/* @ngInject */
export default function (
  $rootScope,
  $scope,
  $window,
  $translate,
  $state,
  $log,
  authService,
  notify,
  configurationService,
  i18n,
  featureService,
  keycloakService,
  Helpers
) {
  const vm = this;

  $rootScope.$emit('stop-error-retry');

  vm.controlPanelRegisterAsUrl = configurationService.getControlPanelRegisterAsUrl();
  vm.controlPanelRecoverPassword = configurationService.getControlPanelRecoverPassword();
  vm.isDistributorChildOrParent = configurationService.isDistributorChildMarketplace() || configurationService.isParentMarketplace();

  vm.loggingIn = false;
  vm.socialLoginFeature = false;
  vm.loginNow = loginNow;
  $window.ssoPopup = undefined; // NOSONAR

  let ssoPopupWatch = null;
  const absControlPanelRegisterUrl = `${location.origin}${vm.controlPanelRegisterAsUrl}`;

  generateKeycloakLoginUrl(i18n.current());

  function generateKeycloakLoginUrl(language) {
    Promise.any([featureService.isEnabled('keycloak'), featureService.isEnabled('ldap')])
      .then(() => {
        vm.keycloakFeature = true;
        vm.ssoLoginUrl = keycloakService.createLoginUrl(language, $state.href('sso', {}, { absolute: true }));
        keycloakService.addListener(onKeycloakSuccess, onKeycloakError);

        // check if popup has been blocked
        if (ssoPopupWatch) ssoPopupWatch();
        ssoPopupWatch = $scope.$watch(() => $window.ssoPopup, popup => {
          if (angular.isUndefined(popup)) return; // default value
          if (popup === null) { // null means popup blocked
            keycloakService.login(language, absControlPanelRegisterUrl);
          }
        });

        $scope.$on('$destroy', () => {
          if (ssoPopupWatch) ssoPopupWatch();
          $rootScope.$emit('start-error-retry');
        });
      })
      .catch(() => $log.debug('Keycloak disabled'));
  }

  function onKeycloakSuccess(subject, token) {
    return authService
      .tokenLogin(subject, token, Helpers.AUTH_PROVIDERS.keycloak)
      .then(() => $translate('cd-login.login-success'))
      .then(notify.success)
      .tap(() => vm.startOrderAfterLogin ? $scope.$broadcast('open-place-order-dialog') : angular.noop())
      .tap($scope.closeThisDialog);
  }

  function onKeycloakError() {
    notify.error($translate.instant('keycloak.authentication-error'));
  }

  function loginNow(username, password) {
    vm.loggingIn = true;
    return authService
      .login({ email: username, password: password }, true)
      .then(() => {
        $translate('cd-login.login-success').then(notify.success);
        $state.go('marketplace.main', {}, {reload: 'marketplace.main'});
      })
      .catch(() => vm.loggingIn = false);
  }

  const unlistenLanguageChanged = $scope.$on('language-changed', (ev, language) => generateKeycloakLoginUrl(language));
  $scope.$on('$destroy', () => unlistenLanguageChanged());
}

