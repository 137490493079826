/* @ngInject */
export default function ($scope, $stateParams, $location, $translate) {
  $scope.orderPage = $scope.$parent.$parent.orderPage = true;
  $scope.selectedHashCoupon = $stateParams.coupon;
  $scope.isEmpty = _.isEmpty;

  $scope.$emit('change-version', $stateParams.version);

  $scope.changeVersionParameter = version => $location.search('version', version.id);

  $scope.$on('$destroy', () => {
    $scope.$emit('change-version', void 0);
    $scope.orderPage = $scope.$parent.$parent.orderPage = false;
  });

  $scope.mergeBillingItems = (apiBillingItems, classicBillingItems) => {
    return Object.assign({}, apiBillingItems, classicBillingItems);
  };

  $scope.mustShowProportionalSetup = (billingItemGroup) => {
    if (billingItemGroup.type !== 'VOLUME') return false;
    return _.some(billingItemGroup, billingItemValue => !!billingItemValue.proportionalSetup);
  };

  $scope.getSetupFromRange = (billingItemGroup) => {
    const range = _findRange(billingItemGroup);
    return range.setup;
  };

  $scope.getProportionalSetupFromRange = (billingItemGroup) => {
    const range = _findRange(billingItemGroup);
    return range.proportionalSetup;
  };

  $scope.getPriceFromRange = (billingItemGroup) => {
    const range = _findRange(billingItemGroup);
    return range.price;
  };

  $scope.getSetupFromEstimate = (estimate, billingItemGroup) => {
    return _sumEstimateLines(estimate, 'BILLINGITEMSETUP', billingItemGroup);
  };

  $scope.getProportionalSetupFromEstimate = (estimate, billingItemGroup) => {
    return _sumEstimateLines(estimate, 'BILLINGITEMPROPORTIONALSETUP', billingItemGroup);
  };

  $scope.getPriceFromEstimate = (estimate, billingItemGroup) => {
    return _sumEstimateLines(estimate, 'BILLINGITEMCOST', billingItemGroup);
  };

  $scope.getRangeLabel = (billingItemValue) => {
    if (billingItemValue.tag) return billingItemValue.tag;

    if (_.isNil(billingItemValue.end)) {
      const over = $translate.instant('marketplace.billing-items.over');
      const quantity = billingItemValue.start - 1;
      const unit = $translate.instant(billingItemValue.item.unit, {PLURALIZE: quantity}, 'messageformat');
      return `${over} ${quantity} ${unit}`;
    }

    const upTo = $translate.instant('marketplace.billing-items.up-to');
    const quantity = billingItemValue.end;
    const unit = $translate.instant(billingItemValue.item.unit, {PLURALIZE: quantity}, 'messageformat');
    return `${upTo} ${quantity} ${unit}`;
  };

  function _findRange(billingItemGroup) {
    // the input can be empty, so we enforce quantity to start
    const quantity = billingItemGroup.quantity || billingItemGroup[0].start;

    return _.findLast(billingItemGroup, billingItemValue => {
      return quantity >= billingItemValue.start && (!billingItemValue.end || quantity <= billingItemValue.end);
    });
  }

  function _sumEstimateLines(estimate, lineType, billingItemGroup) {
    if (!estimate) return;

    const lines = _.filter(estimate.lines, line =>
      line.billingItem && line.billingItem.url === billingItemGroup[0].item.self && line.type === lineType
    );
    return _.sumBy(lines, line => line.priceExcludingVAT);
  }
}
