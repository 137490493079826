const SKINS = [
  'BLUE',
  'YELLOW',
  'GREEN',
  'BLACK-GREEN',
  'BLACK-RED',
  'GRAY-GREEN',
  'GRAY-RED',
  'REDDISH-BLUE',
  'GRAY-BLUE'
];

import checkeredImage from '../../images/checkered.png';

export default function () {
  return {
    restrict: 'E',
    templateUrl: 'views/directive/cd-reseller-sidenav.html',
    controllerAs: 'vm',
    controller: cdResellerSidenavController,
    scope: {}
  };
}

/* @ngInject */
function cdResellerSidenavController(
  $rootScope,
  $scope,
  $timeout,
  $mdSidenav,
  $translate,
  $window,
  currentUser,
  FileService,
  notify,
  settingsService,
  configurationService,
  $anchorScroll
) {
  const vm = this, elementId = 'cd-reseller-sidenav';

  vm.checkeredImage = checkeredImage;
  vm.isAdministrator = currentUser.isAdministrator();
  vm.isSuperAdmin = currentUser.isSuperAdmin();
  vm.saveSettings = saveSettings;
  vm.changeLinksTag = changeLinksTag;
  vm.availableSkins = SKINS;
  vm.updateSkin = updateSkin;
  vm.configuration = angular.copy($window.config.default);
  vm.configuration.environment = configurationService.getCmwEnvironment();
  vm.configuration.languages = vm.configuration.languages || [];
  vm.availableLanguages = configurationService.getAvailableLanguages();
  vm.isDistributorMarketplace = configurationService.isDistributorChildMarketplace();
  vm.isParentMarketplace = configurationService.isParentMarketplace();
  vm.scrollTo = (index, id) => $timeout(() => $anchorScroll(id));
  vm.colorPickerOptions = {
    placeholder: $translate.instant('cd-reseller.banner-background-color'),
    format: 'hex8String',
    restrictToFormat: true,
    preserveInputFormat: false,
    allowEmpty: true,
    alpha: true
  };

  vm.getLanguagesDisplay = () =>
    vm.configuration.languages.length > 0 ?
      vm.configuration.languages.join(', ') :
      vm.availableLanguages.join(', ');

  $scope.$on('language-changed', activate);

  /////////////////

  function activate() {
    if (!$mdSidenav(elementId).isOpen()) return Promise.resolve();

    vm.configuration.ownerUsername = vm.configuration.ownerUsername || vm.configuration.resellerName;
    vm.configuration.marketplaceType = vm.configuration.marketplaceType || _getMarketplaceTypeFromResellerMode();

    return settingsService.getSettings().then(initializeSettings);
  }

  function initializeSettings(settings) {
    vm.settings = settings;
    vm.settings.links = vm.settings.links || [];
    vm.settings.catalogMode = vm.settings.catalogMode || 'STANDARD';
    vm.settings.catalogModes = ['STANDARD', 'PRICE_LIST', 'SHOWCASE', 'ANON_SHOWCASE'];
    vm.settings.banner.links = initSocialLinks(s => s, 2, vm.settings.banner.links);
    vm.settings.partnersBanner = vm.settings.partnersBanner || { backgroundColor: null };
    vm.configuration.languages = configurationService.getLanguages() || [];
    vm.settings.features = vm.settings.features || {};
    vm.settings.homepageProductsCount = vm.settings.homepageProductsCount || 8;
    vm.settings.homepageCategoriesCount = vm.settings.homepageCategoriesCount || 3;

    initializeNewFields();

    vm.settings.links = initFooterLinks(w => `Group ${parseInt(w / 3) + 1}`, 12, vm.settings.links);
  }

  function initializeNewFields() {
    if (!vm.settings.legalSentences || vm.settings.legalSentences.length === 0) {
      const oldLegalSentence = vm.settings.tosPrivacySentence;
      vm.settings.legalSentences = oldLegalSentence ? [oldLegalSentence] : [];
      vm.settings.tosPrivacySentence = null;
    }

    if (!_.find(vm.settings.links, { tag: 'blog' }))
      vm.settings.links.push({ tag: 'blog', anchor: 'blog', url: vm.settings.features ? vm.settings.features.blogUrl : '' });

    if (!_.find(vm.settings.links, { tag: 'docs' }))
      vm.settings.links.push({ tag: 'docs', anchor: 'docs', url: '' });
  }

  function _getMarketplaceTypeFromResellerMode() {
    if (configurationService.isResellerChildMarketplace()) return 'RESELLER';
    if (configurationService.isParentMarketplace()) return 'PARENT';
    return 'REGULAR';
  }

  function initSocialLinks(tagFn, n, links = []) {
    let l = angular.copy(links);
    // create n links object [{weight: 0, tag: tagFn(0)}...{weight: n-1, tag: tagFn(n-1)}]
    if (l.length < n) {
      for (let i = l.length; i < n; i++)
        l.push({ weight: i, tag: tagFn(i) });
    }

    return l.slice(0, n);
  }

  function initFooterLinks(tagFn, n, links = []) {
    let l = angular.copy(links);
    // create n links object [{weight: 0, tag: tagFn(0)}...{weight: n-1, tag: tagFn(n-1)}]
    if (l.length - 2 < n) { // remove blog and docs links
      for (let i = l.length - 2; i < n; i++)
        l.push({ weight: i, tag: tagFn(i) });
    }

    return l;
  }

  function saveSettings(_settings) {
    const settings = angular.copy(_settings);

    settings.legalSentences = _.filter(settings.legalSentences, s => !!s);

    return Promise.resolve()
      .then(() => uploadImage(_settings.logo))
      .tap(logo => settings.logo = logo)
      .then(() => uploadImage(_settings.favicon))
      .tap(favicon => settings.favicon = favicon)
      .then(() => uploadImage(_settings.banner.logo))
      .tap(bannerLogo => settings.banner.logo = bannerLogo)
      .then(() => uploadImage(_settings.partnersBanner.logo))
      .tap(partnersBanner => settings.partnersBanner.logo = partnersBanner)
      .then(() => settingsService.saveSettings(settings, false, vm.configuration.ownerUsername))
      .then(() => vm.isAdministrator ?
        settingsService.saveConfiguration(vm.configuration) :
        settingsService.patchConfiguration(vm.configuration)
      )
      .then(() => $rootScope.$broadcast('marketplace-configuration-changed', angular.copy(vm.configuration)))
      .then(() => $mdSidenav(elementId).close())
      .then(() => $translate('cd-reseller-sidenav.settings-saved'))
      .then(message => notify.success(message));
  }

  function uploadImage(image) {
    if(!image || angular.isString(image)) return Promise.resolve(image);
    return FileService.uploadImage(image).then(image => image.objectUrl);
  }

  function changeLinksTag(links, tag) {
    links.forEach(link => link.tag = tag || 'Group');
  }

  function updateSkin(skin) {
    vm.settings.skin = skin.toUpperCase();
    $rootScope.$broadcast('refresh-theme', skin);
  }

  $timeout(() => {
    let sideNav = $mdSidenav(elementId);
    vm.close = sideNav.close;
    sideNav.onClose(() => {
      delete vm.settings;
      angular.element('#marketplace').removeClass('slide-right');
      $rootScope.$broadcast('refresh-theme');
      settingsService.invalidateCache();
    });
    $scope.$watch(() => sideNav.isOpen(), open => open ? activate() : angular.noop());
  });
}

/* jshint ignore:start */
_('cd-reseller-sidenav.settings-saved'); // NOSONAR
_('cd-reseller.banner-background-color'); // NOSONAR
/* jshint ignore:end */
