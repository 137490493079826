/* @ngInject */
export default function (Restangular, UrlEntityService, Upload) {
  return {
    retrieveAll: retrieveAll,
    uploadImage: uploadImage
  };

  function retrieveAll(urlEntitiesFiles) {
    return UrlEntityService.retrieveList(urlEntitiesFiles);
  }

  function uploadImage(logoFile) {
    return uploadFile(logoFile, 'publicUserFile');
  }

  function uploadFile(file, type, attributes={}) {
    if(!File.prototype.isPrototypeOf(file)) throw new Error('Not a valid file');

    return Promise.try(() => {
      return new Promise((resolve, reject) => {
        return Upload.upload({
          url: Restangular.one(type).getRequestedUrl(),
          data: _.extend({ file: file }, attributes)
        })
          .then(
            file => resolve(Restangular.restangularizeElement(null, file.data, type)),
            error => reject(error)
          );
      });
    });
  }
}
