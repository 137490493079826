/* @ngInject */
export default function (currentUser) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      vat: '=vat',
      disable: '=disable'
    },
    controller: ['$scope', function ($scope) {
      $scope.isLogged = currentUser.user;
    }],
    template: `
        <span>
          <small ng-if="disable || !vat || !isLogged">
            (<span class="vat-line">{{"cd-vat.vat-excluded"|translate}}</span>)
          </small>
          <small ng-if="!disable && vat && isLogged">
            (<span class="vat-line">{{"cd-vat.vat"|translate}}&thinsp;{{vat}}%&thinsp;{{"cd-vat.included"|translate}}</span>)
          </small>
        </span>`
  };
}

