window.config = window.config || {};

window.config.default = window.config.default || {
  endpoint: {
    url: 'http://localhost:8082/cmw'
  },
  endpointMonitor: {
    url: 'http://localhost:8082/monitor'
  },
  endpointDeployer: {
    url: 'http://localhost:8082/deployer'
  },
  endpointLogging: {
    url: 'http://localhost:8082/logger'
  },
  endpointVivace: {
    url: void 0
  },
  marketplaceApiUrl: 'https://staging.cloudesire.com/marketplace',
  feedbackApiUrl: 'https://staging.cloudesire.com/feedback',
  marketplaceSentryDsn: 'https://83109ff76bdd43bba95e6d7c86910d11@sentry.io/358685',
  env: 'dev',
  cmwEnvironment: 'testEnvironment',
  baseUrl: '/',
  controlPanelBaseUrl: '/cp/',
  marketplaceToControlPanelBaseUrl: '/cp/',
  marketplaceProductPath: '/category/{0}/{1}/product/{2}/{3}',
  controlPanelProductPath: '/admin/catalog/product/{0}/{1}/edit',
  controlPanelLoginPath: '/login',
  disableMarketplace: true,
  loginUrl: undefined,
  randomRatingLimitReviews: 5,
  gaCode: '',
  tagManagerCode: '',
  adWordsObject: {
    conversionId: '',
    tryLabel: '',
    buyLabel: ''
  },
  gcloudStorageBaseUrl: 'https://storage.googleapis.com',
  bucketName: 'marketplace-test',
  stripeKey: 'pk_test_0MKOVQg8mJPHloZD99PPdV39',
  additionalThemes: []
};

if(typeof window !== 'undefined') window.Configuration = window.config.default;
