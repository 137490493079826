const routerModule = angular
  .module('cloudesireApp.router', ['ui.router']);

routerModule
  .run(runFn)
  .config(configFn);

export default routerModule;

/* @ngInject */
function runFn(
  $rootScope,
  currentUser,
  $log,
  $location,
  $analytics,
  $window,
  $state,
  localStorageService,
  authService,
  $transitions,
  prerenderService,
  keycloakService,
  Helpers,
  userEditService,
  configurationService
) {
  const trackPage = _.debounce(where => $analytics.pageTrack(where), 1000);

  $transitions.onStart({ from: '**', to: '**' }, stateChangeStartInterceptor);
  $transitions.onSuccess({ from: '**', to: '**' }, stateChangeSuccessCallback);
  $transitions.onError({ from: '**', to: '**' }, stateChangeErrorInterceptor);

  function stateChangeErrorInterceptor(transition) {
    const to = transition.$to().name;
    const toParams = transition.params('to');
    const from = transition.$from().name;

    if(to === from) return true;
    else {
      try {
        let errorType = transition.error().type;
        if (errorType === 5 || errorType === 2) return true; // transition ignored or transition superseded
      } catch (e) {
        $log.error(e);
      }

      $log.error(`Transition from ${from} to ${to} aborted, user has been redirected to the error page`);
      $rootScope.desiredState = to;
      $rootScope.desiredStateParams = toParams;
      return $state.go('marketplace.error');
    }
  }

  function stateChangeStartInterceptor(transition) {
    const toParams = transition.params();

    prerenderService.removeMetas();

    if (currentUser.user && currentUser.user.doNotTrack) {
      $window[`ga-disable-${$window.config.default.gaCode}`] = true;
      $log.debug('Opt-Out tracking');
    }

    // user is already logged
    if (currentUser.isLogged) return;

    var username = toParams.username || localStorageService.get('username');
    var token = toParams.username ? toParams.token : localStorageService.get('token');

    if (keycloakService.isAuthenticated()) {
      return updateKeycloakUser(keycloakService.getToken())
        .then(() => login(keycloakService.getSubject(), keycloakService.getToken(), Helpers.AUTH_PROVIDERS.keycloak))
        .catch(error => {
          keycloakService.clearToken();
          throw error;
        });
    } else if (username && token) {
      return login(username, token, Helpers.AUTH_PROVIDERS.cloudesire)
        .catch(cleanLocalStorage);
    }

    function login(username, token, provider) {
      return authService.tokenLogin(username, token, provider);
    }

    function updateKeycloakUser(token) {
      return keycloakService
        .getProfile()
        .then(profile => {
          const user = {
            name: profile.given_name, // jshint ignore:line
            surname: profile.family_name, // jshint ignore:line
            email: profile.email,
            userName: profile.preferred_username, // jshint ignore:line
            keycloakToken: token
          };

          return userEditService.create(user, 'ROLE_USER');
        });
    }

    function cleanLocalStorage() {
      localStorageService.remove('username');
      localStorageService.remove('token');
      localStorageService.remove('token-provider');
    }

    return true;
  }

  function stateChangeSuccessCallback() {
    const unwatchTitle = $rootScope.$watch(() => document.title, (newTitle, oldTitle) => {
      if (newTitle === oldTitle || !newTitle) return;
      if (configurationService.isProduction()) {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      }
      trackPage($location.url());
      unwatchTitle();
    });

    return true;
  }
}

/* @ngInject */
function configFn(
  $stateProvider,
  $urlRouterProvider,
  $locationProvider,
  $browserCacheProvider,
  configurationServiceProvider
) {
  $locationProvider.html5Mode(configurationServiceProvider.isProduction());

  $browserCacheProvider.addCustomCacheRule(/login/, /user\/me/);
  $browserCacheProvider.addCustomCacheRule(/login/, /product/);
  $browserCacheProvider.addCustomCacheRule(/login/, /productVersion/);
  $browserCacheProvider.addCustomCacheRule(/logout/, /product/);
  $browserCacheProvider.addCustomCacheRule(/logout/, /productVersion/);

  $stateProvider
    .state('marketplace', {
      abstract: true,
      url: '?hl&paid&gclid&state&session_state&code&error',
      reloadOnSearch: false,
      ncyBreadcrumb: {
        label: 'Home'
      },
      templateUrl: 'views/marketplace/marketplace-abstract.html',
      controllerAs: 'vm',
      controller: 'MarketPlaceAbstractCtrl as vm',
      onEnter: () => angular.element('html').removeClass('control-panel-body')
    })
    .state('marketplace.error', {
      url: '/error',
      controller: 'MarketplaceErrorCtrl',
      controllerAs: 'vm',
      templateUrl: 'views/marketplace/error.html',
      ncyBreadcrumb: {
        label: '{{ "marketplace.error-page"|translate }}'
      },
    })
    .state('sso', {
      url: '/sso',
      template: 'Loading...',
      controller: function ($window, localStorageService) {
        'ngInject';
        const keycloak = $window.keycloak;
        localStorageService.set('token-provider', 'keycloak');
        $window
          .opener
          .ssoLogin(
            keycloak.subject,
            keycloak.token,
            keycloak.refreshToken,
            keycloak.idToken,
            keycloak.timeSkew
          );
      },
      ncyBreadcrumb: {
        label: 'sso'
      }
    })
    .state('marketplace.main', {
      url: '/?username&token&q&category',
      reloadOnSearch: false,
      controller: 'MarketPlaceCtrl as vm',
      templateUrl: 'views/marketplace/marketplace.html',
      ncyBreadcrumb: {
        skip: true // Never display this state in breadcrumb.
      },
      resolve: {
        hasFeaturedProducts: Restangular => {
          'ngInject';
          return Restangular
            .withConfig(RestangularConfigurer => RestangularConfigurer.setFullResponse(true))
            .all('product').head({ featured: true }).then(response => parseInt(response.headers('cmw-totalitems')) > 0);
        }
      }
    })
    .state('marketplace.tag', {
      url: '/tag/:tag',
      reloadOnSearch: false,
      controller: 'MarketPlaceTagCtrl as vm',
      templateUrl: 'views/marketplace/marketplace-tag.html',
      ncyBreadcrumb: {
        label: '{{ "cd.breadcrumb.applications"|translate:{tag: vm.tag} }}'
      }
    })
    .state('marketplace.company', {
      url: '/company/:company/:companySlug',
      controller: 'MarketPlaceCompanyCtrl as vm',
      templateUrl: 'views/marketplace/marketplace-company.html',
      ncyBreadcrumb: {
        label: '{{vm.company.name}}'
      },
      resolve: {
        product: function () { },
        company: ($stateParams, Company, i18n, prerenderService) => {
          'ngInject';
          return Company
            .one($stateParams.company)
            .get({ language: i18n.current() })
            .catch(e => {
              prerenderService.set404();
              throw e;
            });
        }
      }
    })
    .state('marketplace.product', {
      url: '/:product/:categorySlug/:productSlug?coupon&orderFlow',
      reloadOnSearch: false,
      ncyBreadcrumb: {
        label: '{{vm.product.name}}'
      },
      resolve: {
        productVersions: ($stateParams, $translate, i18n, productVersionEditService) => {
          'ngInject';
          return new Promise((resolve, reject) => {
            $translate.onReady(() => {
              productVersionEditService
                .retrieveAllByProduct($stateParams.product, false, true, i18n.current())
                .then(resolve)
                .catch(reject);
            });
          });
        }
      },
      controller: 'MarketPlaceProductCtrl as vm',
      templateUrl: 'views/marketplace/marketplace-product.html'
    })
    .state('marketplace.product.order', {
      url: '/order?version',
      reloadOnSearch: false,
      controller: 'MarketPlaceOrderCtrl',
      ncyBreadcrumb: {
        label: '{{ "marketplace.order-setup.title"|translate }}'
      },
      templateUrl: 'views/marketplace/marketplace-order.html'
    });

  const oldProductPathRegex = /category\/(\d+)\/([a-zA-Z0-9\-]+)\/product\/(\d+)\/([a-zA-Z0-9\-]+)(\/order)?(\/)?\??(.*)?/;
  $urlRouterProvider
    .when(oldProductPathRegex, /* @ngInject */ ($state, $location) => {
      const url = $location.url();
      const slices = url.match(oldProductPathRegex);
      const productId = slices[3];
      const productSlug = slices[4];
      const categorySlug = slices[2];
      const parameters = slices[7] ? '?' + slices[7] : '';
      const orderPage = !!slices[5];

      let href;

      if (!orderPage)
        href = $state.href('marketplace.product', {
          product: productId,
          categorySlug: categorySlug,
          productSlug: productSlug
        });
      else
        href = $state.href('marketplace.product.order', {
          product: productId,
          categorySlug: categorySlug,
          productSlug: productSlug
        });

      location.href = `${href}${parameters}`;
    })
    .otherwise(otherwiseFn);
}

/* @ngInject */
function otherwiseFn($injector) {
  const $state = $injector.get('$state');
  const prerenderService = $injector.get('prerenderService');
  const $window = $injector.get('$window');
  $state.go('marketplace.main').then(() => prerenderService.set301($window.location.origin));
}

/* jshint ignore:start */
_('cd.breadcrumb.applications'); // NOSONAR
_('marketplace.error-page'); // NOSONAR
/* jshint ignore:end */
