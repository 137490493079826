/* @ngInject */
export default function (
  $rootScope,
  $q,
  $http,
  configurationService,
  Restangular,
  localStorageService,
  notify,
  i18n,
  Helpers
) {
  return {
    init: function (username, token, provider) {
      const AUTH_PROVIDERS = Helpers.AUTH_PROVIDERS;

      if (provider === AUTH_PROVIDERS.cloudesire) {
        $http.defaults.headers.common['CMW-Auth-Token'] = token;
      } else if (provider === AUTH_PROVIDERS.keycloak) {
        $http.defaults.headers.common['Social-Auth-Provider'] = AUTH_PROVIDERS.keycloak;
        $http.defaults.headers.common['Social-Auth-User'] = username;
        $http.defaults.headers.common['Social-Auth-Token'] = token;
      } else {
        throw new Error(`Invalid authentication provider ${provider}`);
      }

      const misticaUser = localStorageService.get('mistica-user');
      if (misticaUser) {
        this.activateMisticaMode(misticaUser);
      }
    },
    getMe: function () {
      return Restangular.one('user/me?t='+Date.now()).get();
    },
    getRels: function () {
      let language = i18n.current();

      var args = angular.copy(Array.prototype.slice.call(arguments));
      var entities = _.flattenDeep(_.union([args.shift()]));
      var keys = _.flattenDeep(_.union(args));
      var CachedRestangular = Restangular;
      if ('boolean' === typeof keys[keys.length - 1]) {
        var cache = keys.splice(-1, 1);
        CachedRestangular = Restangular.withConfig(function (RestangularConfigurer) {
          RestangularConfigurer.setDefaultHttpFields({ cache: true === cache });
        });
      }
      var entitiesDefer = [];
      var returnDefer = $q.defer();
      angular.forEach(entities, function (_entity) {
        var entityDefer = $q.defer();
        entitiesDefer.push(entityDefer.promise);
        var resourcesDefer = [];
        if (_entity === undefined) {
          entityDefer.resolve();
        } else {
          angular.forEach(keys, function (_key) {
            // transform a single object into an array of objects
            _entity[_key] = _.flattenDeep(_.union([_entity[_key]]));
            var resourceDefer = $q.defer();
            resourcesDefer.push(resourceDefer.promise);
            var keysDefer = [];
            angular.forEach(_entity[_key], function (obj, k) {
              var keyDefer = $q.defer();
              keysDefer.push(keyDefer.promise);
              obj = angular.copy(obj);
              if (obj === undefined || obj.url === undefined) {
                keyDefer.resolve();
              } else {
                var path = obj.url.match('(.*)(\\/)(\\d+)');
                var resourceName = path[1];
                var id = path[3];
                CachedRestangular.one(resourceName, id).get({ language: language }).then(function (resource) {
                  _entity[_key][k] = resource;
                  keyDefer.resolve();
                });
              }
            });
            $q.all(keysDefer).then(function () {
              _entity[_key] = 1 === _entity[_key].length ? _entity[_key][0] : _entity[_key];
              resourceDefer.resolve();
            });
          });
        }
        $q.all(resourcesDefer).then(function () {
          entityDefer.resolve();
        });
      });

      $q.all(entitiesDefer).then(function () {
        var promises = 1 === entities.length ? entities[0] : entities;
        returnDefer.resolve(promises);
      });

      return returnDefer.promise;
    },
    postFile: function (endpoint, data) {
      return $http.post(configurationService.config.endpoint.url + '/' + endpoint, data,
        {
          transformRequest: angular.identity,
          headers: { 'Content-Type': undefined }
        }
      );
    },
    urlEntityToId: function (urlEntity) {
      var url = urlEntity.url || urlEntity.self;
      if (url === undefined) return;
      return url.substring(url.indexOf('/') + 1);
    },
    activateMisticaMode: function (username) {
      $http.defaults.headers.common['CMW-As-User'] = username;
      localStorageService.remove('features');
      localStorageService.set('mistica-user', username);
      $rootScope.$broadcast('refreshUser');
      notify.info('Mistica mode enabled for user: ' + username);
    },
    deactivateMisticaMode: function () {
      delete $http.defaults.headers.common['CMW-As-User'];
      localStorageService.remove('mistica-user');
      $rootScope.$broadcast('refreshUser');
    }
  };
}
