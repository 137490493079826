/* @ngInject */
export default function configurationService(Restangular) {

  return Restangular.withConfig(restangularConfigurator)
    .service('configuration');

  function restangularConfigurator(R) {
    R.addElementTransformer('configuration', false, function (configuration) {
      configuration.addRestangularMethod('customerInvoicePeriod', 'get', 'customerInvoicePeriod');
      return configuration;
    });
  }
}
