/* @ngInject */
export default function ($window, $document) {
  let directive = {
    restrict: 'A',
    link: cdStickyLink,
    scope: {
      'cdSticky': '=',
      'offset': '=',
      'bottomOffset': '=',
      'startScrollAt': '='
    }
  };

  return directive;

  function cdStickyLink(scope, element) {
    if (scope.cdSticky === false) return;
    const scrollTo = value => element.css('top', value);
    const bottomOffset = isNaN(scope.bottomOffset) ? 0 : parseInt(scope.bottomOffset);
    const startScrollAt = isNaN(scope.startScrollAt) ? 0 : parseInt(scope.startScrollAt);

    function calculatePosition() {
      let offset;

      const elementHeight = element.height();
      const viewportHeight = angular.element(window).height() * 0.8;
      offset = elementHeight >= viewportHeight ? 24 : 80;
      offset = isNaN(scope.offset) ? offset : parseInt(scope.offset);
      const maxTop = Math.abs(element.parent().height() - element.height() - bottomOffset);
      const scrollY = $window.scrollY - element.parent().offset().top;
      let windowScrollY = scrollY + offset - startScrollAt;
      if (windowScrollY < 0) windowScrollY = 0;
      if (windowScrollY > maxTop) windowScrollY = maxTop >= offset ? maxTop : offset;

      scrollTo(windowScrollY);
    }

    $document.on('scroll', calculatePosition);
    scope.$on('$destroy', () => $document.off('scroll', calculatePosition));
  }
}

