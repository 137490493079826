import * as Sentry from '@sentry/browser';

import './vendor.module';
import './resource.module';
import './router.module';

import './configuration';
import './factory';
import './service';
import './filter';
import './directive';
import './controller';

require('../styles/hide.css');

const itPo = require('../../po/it.po');
const enPo = require('../../po/en.po');

// workaround to https://github.com/mikeedwards/po2json/issues/77
const itTranslations = unescapePlaceholders(itPo);
const enTranslations = unescapePlaceholders(enPo);

Sentry.init({
  dsn: window.config.default.marketplaceSentryDsn,
  environment: window.SENTRY_ENVIRONMENT,
  blacklistUrls: /(localhost|127(.[0-9]+)+)(\:\d+)?/,
  release: window.SENTRY_RELEASE
});

angular.lowercase = text => text ? text.toLowerCase() : text;
angular.uppercase = text => text ? text.toUpperCase() : text;

const appModule = angular.module('cloudesireApp', [
  'cloudesireApp.vendor',
  'cloudesireApp.resource',
  'cloudesireApp.router',
  'cloudesireApp.factory',
  'cloudesireApp.service',
  'cloudesireApp.filter',
  'cloudesireApp.directive',
  'cloudesireApp.controller'
]);

appModule.config(appConfig);
appModule.run(appRun);

export default appModule;

/* @ngInject */
function appRun(
  localStorageService,
  currentUser,
  authService,
  $rootScope,
  $interval,
  i18n,
  $q,
  $window
) {
  $window.Promise = $q;

  let lastSessionLanguage = localStorageService.get('language');
  let userLanguage = currentUser.user ? currentUser.user.language : lastSessionLanguage;
  i18n.changeLanguage(userLanguage, false); // forceLanguageTo can be undefined
  let intervalRef;
  $rootScope.$on('refreshUser', () => {
    authService.refresh().then(() => {
      if(!intervalRef) intervalRef = $interval(authService.refresh, 30000);
    }, () => {
      if(intervalRef) {
        $interval.cancel(intervalRef);
        intervalRef = void 0;
      }
    });
  });
}

/* @ngInject */
function appConfig(
  $logProvider,
  localStorageServiceProvider,
  configurationServiceProvider,
  $translateProvider,
  tmhDynamicLocaleProvider,
  $breadcrumbProvider,
  $browserCacheProvider,
  $analyticsProvider,
  $mdAriaProvider,
  $provide
) {
   $mdAriaProvider.disableWarnings();

  // storage configurations
  localStorageServiceProvider.setPrefix('cd');

  // language configuratoins
  $translateProvider.useSanitizeValueStrategy(null);
  $translateProvider.translations('it', itTranslations);
  $translateProvider.translations('en', enTranslations);
  $translateProvider.addInterpolation('$translateMessageFormatInterpolation');
  $translateProvider.preferredLanguage(configurationServiceProvider.getDefaultLanguage());
  $translateProvider.useLoaderCache(true);
  tmhDynamicLocaleProvider.localeLocationPattern('https://cdnjs.cloudflare.com/ajax/libs/angular-i18n/1.7.5/angular-locale_{{locale}}.js');

  // CD configurations
  if(!configurationServiceProvider.isProduction()) {
    $logProvider.debugEnabled(true);
    $analyticsProvider.developerMode(true);
  }

  $breadcrumbProvider.setOptions({
    includeAbstract: true,
    template: `<ol
      itemscope
      itemtype="http://schema.org/BreadcrumbList"
      class="breadcrumb"
      layout="row"
      layout-align="start center"
      layout-xs="column"
      layout-align-xs="start start">
      <li ng-repeat="step in steps" ng-class="{active: $last}" ng-switch="$last" itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
          <a itemprop="item" ng-switch-when="false" ng-if="step.name !== 'marketplace'" ui-sref="{{step.ncyBreadcrumbStateRef}}">{{step.ncyBreadcrumbLabel}}</a>
          <a itemprop="item" ng-switch-when="false" ng-if="step.name === 'marketplace'" ui-sref="marketplace.main">{{step.ncyBreadcrumbLabel}}</a>
          <span itemprop="name" ng-switch-when="true">{{step.ncyBreadcrumbLabel}}</span>
          <meta itemprop="position" content="{{$index+1}}" />
      </li>
    </ol>`
  });

  $browserCacheProvider.setHashParameter('cdRev');

  $analyticsProvider.trackStates(false);
  $analyticsProvider.trackRoutes(false);
  $analyticsProvider.virtualPageviews(false);
  $analyticsProvider.firstPageview(false);
  $analyticsProvider.withAutoBase(false);

  $provide.decorator('$exceptionHandler', ['$log',
    function ($log) {
      return (exception, cause) => {

        //No cause, ignore unhandled rejection warnings for undefined
        if (!cause && angular.isString(exception)) {
          return;
        }

        //Capture exception and log
        Sentry.captureException(exception);
        $log.error(exception);
      };
    }
  ]);
}

function unescapePlaceholders(translations) {
  const t = _.map(translations, (v, k) => {
    return [k, v.replace(new RegExp(/\\/, 'g'), '')];
  });

  return _.fromPairs(t);
}
