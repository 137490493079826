/* @ngInject */
export default function (Restangular, apiFactory, _) {
  var couponService = Restangular.withConfig(function (RestangularConfigurer) {
    RestangularConfigurer.setFullResponse(true);
  }).service('coupon');

  function getList(obj) {
    return couponService.getList(obj).then(retrieveProductVersion);
  }

  function retrieveProductVersion(objs) {
    return apiFactory.getRels(objs.data, 'productVersion').then(function (data) {
      objs.data = _.union(_.flattenDeep([data]));
      retrieveProduct(objs.data);
      return objs;
    });
  }

  function retrieveProduct(objs) {
    angular.forEach(objs, function (obj) {
      return apiFactory.getRels(obj.productVersion, 'product').then(function (result) {
        obj.productVersion.product = result.product;
      });
    });
    return objs;
  }

  return {
    getList: getList,
    post: couponService.post
  };
}
