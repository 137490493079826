import cdAutoActive from './cd-auto-active';
import cdBillingItemSlider from './cd-billing-item-slider';
import cdBillingItemTier from './cd-billing-item-tier';
import cdCheckbox from './cd-checkbox';
import cdCountrySelect from './cd-country-select';
import cdCustomTheme from './cd-custom-theme';
import cdEllipsis from './cd-ellipsis';
import cdEstimateDetail from './cd-estimate-detail';
import cdFixed from './cd-fixed';
import cdGlobalProgress from './cd-global-progress';
import cdInspectlet from './cd-inspectlet';
import cdLogin from './cd-login';
import cdLogout from './cd-logout';
import cdMagnificPopup from './cd-magnific-popup';
import cdMarketplaceLanguageSwitcher from './cd-marketplace-language-switcher';
import cdObjectFit from './cd-object-fit';
import cdOnFinishRenderAction from './cd-on-finish-render-action';
import cdOnFinishRender from './cd-on-finish-render';
import cdCarousel from './cd-carousel';
import cdPassword from './cd-password';
import cdPlaceOrder from './cd-place-order';
import cdRadio from './cd-radio';
import cdResellerSidenav from './cd-reseller-sidenav';
import cdStarRating from './cd-star-rating';
import cdStartOrderFlowByParameter from './cd-start-order-flow';
import cdSticky from './cd-sticky';
import cdStructuredProductData from './cd-structured-product-data';
import cdStructuredSearchBoxData from './cd-structured-search-box-data';
import cdTax from './cd-tax';
import cdUppercase from './cd-uppercase';
import cdAutofocus from './cd-autofocus';
import cdGroupOptionalFilled from './cd-group-optional-filled';

const directiveModule = angular.module('cloudesireApp.directive', []);

directiveModule.directive('cdAutoActive', cdAutoActive);
directiveModule.directive('cdBillingItemSlider', cdBillingItemSlider);
directiveModule.directive('cdBillingItemTier', cdBillingItemTier);
directiveModule.directive('cdCheckbox', cdCheckbox);
directiveModule.directive('cdCountrySelect', cdCountrySelect);
directiveModule.directive('cdCustomTheme', cdCustomTheme);
directiveModule.directive('cdEllipsis', cdEllipsis);
directiveModule.directive('cdEstimateDetail', cdEstimateDetail);
directiveModule.directive('cdFixed', cdFixed);
directiveModule.directive('cdGlobalProgress', cdGlobalProgress);
directiveModule.directive('cdInspectlet', cdInspectlet);
directiveModule.directive('cdLogin', cdLogin);
directiveModule.directive('cdLogout', cdLogout);
directiveModule.directive('cdMagnificPopup', cdMagnificPopup);
directiveModule.directive('cdMarketplaceLanguageSwitcher', cdMarketplaceLanguageSwitcher);
directiveModule.directive('cdObjectFit', cdObjectFit);
directiveModule.directive('cdOnFinishRenderAction', cdOnFinishRenderAction);
directiveModule.directive('cdOnFinishRender', cdOnFinishRender);
directiveModule.directive('cdCarousel', cdCarousel);
directiveModule.directive('cdPassword', cdPassword);
directiveModule.directive('cdPlaceOrder', cdPlaceOrder);
directiveModule.directive('cdRadio', cdRadio);
directiveModule.directive('cdResellerSidenav', cdResellerSidenav);
directiveModule.directive('cdStarRating', cdStarRating);
directiveModule.directive('cdStartOrderFlowByParameter', cdStartOrderFlowByParameter);
directiveModule.directive('cdSticky', cdSticky);
directiveModule.directive('cdStructuredProductData', cdStructuredProductData);
directiveModule.directive('cdStructuredSearchBoxData', cdStructuredSearchBoxData);
directiveModule.directive('cdTax', cdTax);
directiveModule.directive('cdUppercase', cdUppercase);
directiveModule.directive('cdAutofocus', cdAutofocus);
directiveModule.directive('cdGroupOptionalFilled', cdGroupOptionalFilled);

export default directiveModule;
