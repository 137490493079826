let asyncCallInProgress = false;

/* @ngInject */
export default function (settingsService, Helpers) {
  function cdSettingsFilter(setting) {
    if (asyncCallInProgress) return;
    if (!settingsService.getSettingsSync()) {
      asyncCallInProgress = true;
      return settingsService.getSettings().then(settings => {
        asyncCallInProgress = false;
        const profile = { profile: settings };
        return Helpers.deepValue(profile, setting);
      });
    } else {
      const profile = { profile: settingsService.getSettingsSync() };
      return Helpers.deepValue(profile, setting);
    }
  }

  cdSettingsFilter.$stateful = true;
  return cdSettingsFilter;
}
