const productStructuredData = {
  '@context': 'http://schema.org/',
  '@type': 'Product',
  'name': '',
  'image': '',
  'description': '',
  'sku': '',
  'aggregateRating': {
    '@type': 'AggregateRating',
    'ratingValue': '',
    'reviewCount': '',
    'bestRating': '5',
    'worstRating': '0'
  },
  'offers': {
    '@type': 'Offer',
    'priceCurrency': 'EUR',
    'price': '',
    'seller': {
      '@type': 'Organization',
      'name': 'Executive Objects'
    }
  }
};

const breadcrumbListStructuredData = {
  '@context': 'http://schema.org',
  '@type': 'BreadcrumbList',
  'itemListElement': []
};


/* @ngInject */
export default function ($filter, $state) {
  let directive = {
    restrict: 'A',
    link: cdAutoActiveLink,
    scope: {
      product: '=cdStructuredProductData'
    }
  };

  /* @ngInject */
  function cdAutoActiveLink(scope, el) {
    let unwatch = scope.$watch('product', product => {
      if (!product) return;
      unwatch();

      const price = product.recommendedVersion ? product.recommendedVersion.price : 0;

      productStructuredData.name = product.name;
      productStructuredData.image = product.logo.objectUrl;
      productStructuredData.description = $filter('htmlToPlainText')(product.shortDescription);
      productStructuredData.sku = product.sku;
      productStructuredData.aggregateRating.ratingValue = product.averageRating;
      productStructuredData.aggregateRating.reviewCount = product.numberOfRatings;
      productStructuredData.offers.price = price;
      productStructuredData.offers.seller.name = product.company ? product.company.name : '';

      let rawScriptTag = document.createElement('script');
      rawScriptTag.type = 'application/ld+json';
      let scriptTag = angular.element(rawScriptTag);
      scriptTag.text(angular.toJson(productStructuredData));

      el.append(scriptTag);

      breadcrumbListStructuredData.itemListElement.push({
        '@type': 'ListItem',
        'position': 1,
        'item': {
          '@id': $state.href('marketplace.product', {
            product: product.id,
            productSlug: product.sluggedName,
            categorySlug: product.category ? product.category.sluggedName : ''
          }, { absolute: true }),
          'name': product.name
        }
      });

      let breadcrumbRawScriptTag = document.createElement('script');
      breadcrumbRawScriptTag.type = 'application/ld+json';
      let breadcrumbScriptTag = angular.element(breadcrumbRawScriptTag);
      breadcrumbScriptTag.text(angular.toJson(breadcrumbListStructuredData));

      el.append(breadcrumbScriptTag);
    });

  }

  return directive;
}

