/* @ngInject */
export default function ($location, $document) {
  let directive = {
    restrict: 'A',
    controller: cdAutoActiveController
  };

  /* @ngInject */
  function cdAutoActiveController($scope) {
    $scope.scrollTo = scrollTo;
  }

  function scrollTo($event, id) {
    $event.preventDefault();
    $location.hash(id);
    const scrollToRow = angular.element(document.getElementById(id));
    $document.scrollToElementAnimated(scrollToRow, 12);
    return false;
  }

  return directive;
}
