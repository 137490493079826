/* @ngInject */
export default function (
  $stateParams,
  $log,
  $rootScope,
  configurationService,
  inspectletService
) {
  let directive = {
    restrict: 'E',
    link: cdInspectletLink,
    scope: {}
  };

  function cdInspectletLink(scope, el) {
    const fromPaidAD = $stateParams.paid !== void 0 || $stateParams.gclid !== void 0;
    const currentEnvironment = configurationService.getCmwEnvironment();

    if (!fromPaidAD || currentEnvironment !== 'cloudesire') {
      $log.info('Disabling inspectlet');
      return;
    }

    if (inspectletService.isAlreadyInjected()) {
      $log.info('Inspectlet already loaded');
      return;
    }

    inspectletService.injectInspectlet(el);
    let destroyOn = $rootScope.$on('refreshUser', inspectletService.identifyUser);
    scope.$on('$destroy', destroyOn);
  }

  return directive;
}

