/* @ngInject */
export default function (
  $stateParams,
  $location,
  commonState
 ) {
  return {
    restrict: 'A',
    require: '^cdPlaceOrder',
    controller: ($scope, $attrs) => {
      'ngInject';
      const parameter = $attrs.cdStartOrderFlowByParameter;
      const alreadyCalled = commonState.state.alreadyCalled;
      if (!alreadyCalled && angular.isString($stateParams[parameter])) {
        $location.search(parameter, null);
        commonState.state.alreadyCalled = true;
        $scope.$broadcast('start-order', $stateParams[parameter]);
      }
    }
  };
}
