/* @ngInject */
export default function (authService, notify, $translate, $state) {
  return {
    restrict: 'A',
    link: (scope, el) => {
      scope.$on('$destroy', () => el.off('mouseup'));
      el.on('click',
        () => authService.logout()
          .then(() => $translate('cd-logout.logout-success'))
          .then(notify.success)
          .then(() => $state.go('marketplace.main', {}, {reload: 'marketplace.main'}))
      );
    }
  };
}

