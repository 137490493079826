 let template = [
  '<div layout="row" layout-align="start end">',
  '<fa class="star" ng-repeat="r in ratingRange" name="star"></fa>',
  '<fa class="star-o star" ng-repeat="r in maxRatingRange" name="star-o"></fa>',
  '</div>'
].join('');

export default function () {
  var directive = {
    restrict: 'E',
    scope: {
      rating: '@cRating',
      maxRating: '@'
    },
    template: template,
    controller: starRatingController
  };
  return directive;

  /* @ngInject */
  function starRatingController($scope) {
    if ($scope.rating >= 0) {
      $scope.ratingRange = _.range($scope.rating);
      $scope.maxRatingRange = _.range($scope.maxRating - $scope.rating);
    }
  }
}

