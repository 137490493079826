/* @ngInject */
export default function () {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function(scope, elem, attrs, ctrl) {
      const validateGroupFilled = function(value) {
        const form = angular.element(`#${attrs.formId}`);

        const group = form.find(`input[cd-group-optional-filled=${attrs.cdGroupOptionalFilled}]`);
        const filledFields = _.filter(group, (el) => !!angular.element(el).val());
        const isValid = filledFields.length === 1;

        _.map(group, (el) => angular.element(el).controller('ngModel').$setValidity('field', isValid));
        return value;
      };

      ctrl.$parsers.unshift(validateGroupFilled);
    }
  };
}
