/* @ngInject */
export default function (
  $rootScope,
  $http,
  $state,
  $translate,
  $window,
  apiFactory,
  authFactory,
  currentUser,
  notify,
  localStorageService,
  Restangular,
  i18n,
  userEditService,
  Helpers,
  keycloakService,
  featureService
) {
  const error = e => {
    currentUser.isLogged = false;
    currentUser.user = null;
    $translate('401---bad-credentials').then(notify.error);
    throw e;
  };

  const success = function (username, token, provider) {
    apiFactory.init(username, token, provider);
    return apiFactory
      .getMe()
      .then(user => {
        userEditService.analyticsSessionUnification(user.id);
        const role = userEditService.normalizeRole(user.userRole);
        userEditService.pingAnalytics('login', role);
        localStorageService.set('username', username);
        localStorageService.set('token', token);
        localStorageService.set('token-provider', provider);
        currentUser.isLogged = true;
        currentUser.user = user;
        $rootScope.$broadcast('refreshUser');
        $window.skipLdapLogin = true;
        if (currentUser.language) i18n.changeLanguage(currentUser.language);
        return Restangular.copy(currentUser.user);
      });
  };

  this.login = function (user, dontReload = false) {
    const credentials = {
      username: user.email,
      password: user.password
    };

    apiFactory.deactivateMisticaMode(dontReload);

    return authFactory
      .login(credentials)
      .then(response => {
        const token = response.data;
        localStorageService.remove('features');
        return success(credentials.username, token, Helpers.AUTH_PROVIDERS.cloudesire)
          .then(user => {
            if (dontReload) return Promise.resolve(user);
            else $state.reload();
          });
      })
      .catch(error);
  };

  this.refresh = function () {
    if (!currentUser.user) return Promise.reject('User not logged');
    return Restangular.one('user', currentUser.user.id)
      .get()
      .then(user => currentUser.user = user)
      .return(currentUser.user);
  };

  this.tokenLogin = function (username, token, provider) {
    return success(username, token, provider);
  };

  this.logout = function () {
    delete $http.defaults.headers.common['Social-Auth-Provider'];
    delete $http.defaults.headers.common['Social-Auth-User'];
    delete $http.defaults.headers.common['Social-Auth-Token'];
    localStorageService.remove('username');
    localStorageService.remove('token');
    localStorageService.remove('token-provider');
    localStorageService.remove('features');
    localStorageService.remove('language');
    userEditService.analyticsSessionUnification(null);
    currentUser.isLogged = false;
    currentUser.user = null;
    apiFactory.deactivateMisticaMode();

    featureService.isEnabled('ldap')
      .then(() => $window.skipLdapLogin = false)
      .catch(() => $window.skipLdapLogin = true);

    return authFactory.logout()
      .finally(() => {
        delete $http.defaults.headers.common['CMW-Auth-Token'];

        if (keycloakService.isAuthenticated()) {
          keycloakService.logout();
        } else {
          reloadPage();
        }
      });
  };

  function reloadPage() {
    if (!$state.current.abstract) {
      $state.reload();
    }
  }
}
