/* @ngInject */
export default function ($timeout) {
  return {
    restrict: 'A',
    link: scope => {
      if (scope.$parent.$last === true) $timeout(scope.cdOnFinishRenderAction);
    },
    scope: {
      'cdOnFinishRenderAction': '='
    }
  };
}

