import './vendor.import';

export default angular
  .module('cloudesireApp.vendor', [
    'angular-extend-promises',
    'ngMaterial',
    'ngAnimate',
    'pascalprecht.translate',
    'restangular',
    'angularMoment',
    'slugifier',
    'duScroll',
    'picardy.fontawesome',
    'LocalStorageModule',
    'ngMaterial',
    'mdSteppers',
    'ngSanitize',
    'tmh.dynamicLocale',
    'ngBrowserUpdate',
    'updateMeta',
    'ngDialog',
    'ncy-angular-breadcrumb',
    'dibari.angular-ellipsis',
    'ngUrlParser',
    '720kb.tooltips',
    'angular.filter',
    'BrowserCache',
    'vAccordion',
    'angulartics',
    'angulartics.google.analytics',
    'ngFileUpload',
    'cloudesire.common',
    'color.picker',
    'ngQuill'
  ]);
