export default function () {
  var mapArray = function (element, k) {
    var elements = [];
    angular.forEach(element, function (e) {
      if (_.isObject(e) && e.self && (
        e.self.indexOf('dependency') >= 0 ||
        e.self.indexOf('endpointPattern') >= 0 ||
        e.self.indexOf('applicationFileEnvironment') >= 0 ||
        e.self.indexOf('configurationParameter') >= 0
      )) {
        elements.unshift(e);
      } else if (_.isObject(e) && e.self) {
        elements.unshift({ url: e.self });
      } else if (_.isObject(e) && !e.file && !e.put) {
        elements.unshift(e);
      } else if (typeof e === 'string') {
        elements.unshift(e);
      }
    });
    return [k, elements];
  };

  this.convert = function (element) {
    return _.fromPairs(_.map(element,
      function (value, key) {
        if (Array.isArray(value)) {
          return mapArray(value, key);
        }
        else if (_.isObject(value) && value.self && key !== 'role') {
          return [key, value.self];
        } else {
          if (value && value.originalElement) {
            delete value.originalElement;
          }

          return [key, value];
        }
      }
    ));
  };
}
