require('../views/directive/cd-billing-item-slider.html');
require('../views/directive/cd-billing-item-tier-tooltip.html');
require('../views/directive/cd-billing-item-tier.html');
require('../views/directive/cd-estimate-detail.html');
require('../views/directive/cd-login.html');
require('../views/directive/cd-marketplace-language-switcher.html');
require('../views/directive/cd-place-order.html');
require('../views/directive/cd-reseller-sidenav.html');
require('../views/marketplace/cd-billing-information.html');
require('../views/marketplace/cd-login-information.html');
require('../views/marketplace/cd-payment-information.html');
require('../views/marketplace/cd-payment-method-choice.html');
require('../views/marketplace/cd-ssh-key.html');
require('../views/marketplace/cd-tos-privacy.html');
require('../views/marketplace/error.html');
require('../views/marketplace/marketplace-abstract.html');
require('../views/marketplace/marketplace-company.html');
require('../views/marketplace/marketplace-contact-vendor.html');
require('../views/marketplace/marketplace-faq-block.html');
require('../views/marketplace/marketplace-logo.html');
require('../views/marketplace/marketplace-order.html');
require('../views/marketplace/marketplace-product-billing-items-common.html');
require('../views/marketplace/marketplace-product-billing-items-common.input.html');
require('../views/marketplace/marketplace-product-billing-items-common.select.html');
require('../views/marketplace/marketplace-product-billing-items-common.slider.html');
require('../views/marketplace/marketplace-product-billing-items-common.tier.html');
require('../views/marketplace/marketplace-product-billing-items-common.unit-price.html');
require('../views/marketplace/marketplace-product-estimate-block.html');
require('../views/marketplace/marketplace-product-estimate-detail.html');
require('../views/marketplace/marketplace-product-featured.html');
require('../views/marketplace/marketplace-product-partial.html');
require('../views/marketplace/marketplace-product-pay-per-use.html');
require('../views/marketplace/marketplace-product-postpaid.html');
require('../views/marketplace/marketplace-product-prepaid.html');
require('../views/marketplace/marketplace-product-right-column.html');
require('../views/marketplace/marketplace-product-version.html');
require('../views/marketplace/marketplace-product.html');
require('../views/marketplace/marketplace-search-box.html');
require('../views/marketplace/marketplace-search.html');
require('../views/marketplace/marketplace-tag.html');
require('../views/marketplace/marketplace-total-price.html');
require('../views/marketplace/marketplace.html');

import keycloak from 'keycloak-js';
import jquery from 'jquery';
import toastr from 'toastr';
import * as Sentry from '@sentry/browser';

window.feedbackWidget = require('@cloudesire/feedback-widget/build/bundle.js');
window.Keycloak = keycloak;
window.$ = window.jQuery = jquery;
window.toastr = toastr;
window.Raven = Sentry;

window.keygen = require('js-keypair');
window.keygenBundle = require('js-keypair/bundle');

import 'promiz';
import 'webcrypto-shim';

import 'json3';
import 'jquery';
import 'angular';
import 'angular-resource';
import 'angular-animate';
import 'restangular';
import 'moment';
import 'angular-moment';
import 'angular-slugify';
import '@uirouter/angularjs';
import 'angular-scroll';
import 'angular-fontawesome';
import 'harmony-reflect';
import 'toastr';
import 'angular-local-storage';
import 'angular-aria';
import 'angular-messages';
import 'angular-material';
import 'jquery-bridget';
import 'magnific-popup';
import 'angular-translate';
import 'angular-translate-handler-log';
import 'angular-translate-loader-static-files';
import 'messageformat';
import 'angular-translate-interpolation-messageformat';
import 'angular-dynamic-locale';
import 'angular-currency-code/dist/angular-currency-code';
import 'ng-browser-update/dist/ng-browser-update';
import 'ngOrderObjectBy/src/ng-order-object-by';
import 'angular-update-meta/dist/update-meta';
import 'matchmedia-polyfill';
import 'ng-dialog';
import 'angular-breadcrumb';
import 'angular-url-parser/angular-url-parser';
import 'angular-tooltips';
import 'angular-filter';
import 'angular-cache';
import 'angular-browser-cache-manager/dist/scripts/angular-browser-cache-manager';
import 'angular-ellipsis';
import 'v-accordion';
import 'sha-1';
import 'angulartics-google-analytics';
import 'angulartics';
import 'material-steppers';
import 'cookieconsent';
import 'ng-file-upload';
import 'angularjs-color-picker';
import 'angular-extend-promises/angular-extend-promises-without-lodash';
import 'textangular/dist/textAngular-sanitize';
import 'quill';
import 'ng-quill/src/ng-quill';

import '@cloudesire/angular-cloudesire-common';
