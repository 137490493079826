import cdLoginDirectiveController from '../controller/cd-login-directive-controller';

export default function () {
  return {
    restrict: 'A',
    link: (scope, el, attrs, controller) => {
      el.on('click', event => scope.$apply(() =>
        controller.startLogin(event)));
      scope.$on('$destroy', () => el.off('mouseup'));
    },
    controller: cdLoginDirectiveController,
    controllerAs: 'vm'
  };
}
