export default function () {
  return {
    restrict: 'E',
    require: 'ngModel',
    replace: true,
    template: `
        <div id="{{id}}" class="cd-input cd-password">
          <input required autocomplete="{{autocomplete}}" ng-model="ngModel" placeholder="Password" type="{{cdPasswordVm.type}}" class="inspectletIgnore" />
          <fa name="eye" tabindex="-1" ng-click="cdPasswordVm.toggle()" ng-if="cdPasswordVm.type === 'password'" />
          <fa name="eye-slash" tabindex="-1" ng-click="cdPasswordVm.toggle()" ng-if="cdPasswordVm.type === 'text'"/>
        </div>"`,
    scope: {
      id: '@',
      ngModel: '=',
      autocomplete: '@'
    },
    controllerAs: 'cdPasswordVm',
    controller: function () {
      const cdPasswordVm = this;
      cdPasswordVm.type = 'password';
      cdPasswordVm.toggle = toggle;

      function toggle() {
        cdPasswordVm.type =
          cdPasswordVm.type === 'password' ? 'text' : 'password';
      }
    }
  };
}

