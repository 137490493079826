const scope = {
  application: '=cdPlaceOrder',
  estimateRequestObject: '=cdEstimateRequest',
  estimate: '=cdEstimate',
  product: '=cdPlaceOrderProduct',
  productVersion: '=cdPlaceOrderProductVersion'
};

export default function () {
  return {
    restrict: 'A',
    link: (scope, el, attrs, controller) => {
      el.on('click', () => scope.$apply(() =>
        controller.startPlaceOrder()));
      scope.$on('$destroy', () => el.off('mouseup'));
    },
    controller: cdPlaceOrderDirectiveController,
    controllerAs: 'vm',
    transclude: true,
    template: `
        <span ng-if="vm.ctaLabel" class="buy-the-version">
          {{vm.ctaLabel}}
        </span>
        <span ng-if="!vm.ctaLabel && !vm.free && vm.triable" class="try-the-version">
          {{ "marketplace.product.start-free-trial-button"|translate }}
        </span>
        <span ng-if="!vm.ctaLabel && !vm.free && !vm.triable" class="buy-the-version">
          {{ "marketplace.product.buy-now-button"|translate }}
        </span>
        <span ng-if="!vm.ctaLabel && vm.free" class="buy-the-version">
          {{ "marketplace.product.activate-now-button"|translate }}
        </span>
      `,
    scope: scope
  };
}

/* @ngInject */
function cdPlaceOrderDirectiveController(
  $element,
  $scope,
  $rootScope,
  $translate,
  $timeout,
  Restangular,
  configurationService,
  featureService,
  currentUser,
  inspectletService,
  ngDialog,
  notify
) {
  const vm = this;
  let { application, product, productVersion } = $scope;

  application = application || {};
  application.product = application.product || product;
  application.productVersion = application.productVersion || productVersion;

  updatePriceFlags();

  let unwatchVersion = $scope.$watch('application', updatePriceFlags, true);
  let unwatchEstimate = $scope.$watch('estimate', updatePriceFlags);

  vm.startPlaceOrder = (orderType, customClass) => {
    if (currentUser.isLogged && currentUser.user.userRole !== 'ROLE_USER') {
      $translate('cd-place-order.only-customers-can-place-orders')
        .then(notify.error);
      return;
    }

    validateOrder($scope.estimateRequestObject)
      .then(() => {
        const ngDialogApplication = angular.copy(application);
        ngDialogApplication.orderType = orderType || ngDialogApplication.orderType;
        ngDialogApplication.selfBilled = $scope.estimate.selfBilled;
        inspectletService.tagSession('form-opened');

        ngDialog.open({
          controller: 'CDPlaceOrderController',
          resolve: {
            application: () => ngDialogApplication,
            environment: () => configurationService.getCmwEnvironmentObject(),
            estimate: () => $scope.estimate
          },
          controllerAs: 'vm',
          className: 'cd-dialog',
          appendClassName: `cd-place-order-dialog ${customClass}`,
          template: 'views/directive/cd-place-order.html',
          appendTo: '.dialog-parent',
          showClose: false,
          closeByDocument: false,
          closeByEscape: true,
          closeByNavigation: false
        });
      });
  };

  function validateOrder(estimateRequestObject) {
    return Restangular
      .all('budgetEstimate')
      .all('validate')
      .post(estimateRequestObject);
  }

  function updatePriceFlags() {
    const productVersion = application.productVersion;
    const hasRequiredConfigurationParameters = productVersion.hasRequiredConfigurationParameters;
    const hasMissingRequiredConfigurationParameters = hasRequiredConfigurationParameters &&
      _.some(productVersion.requiredConfigurationParameters, requiredConfParameter =>
        _.isNil(application.configurationParameters[requiredConfParameter.self])
      );

    const locked = (!application.hashCoupon && productVersion.couponConfiguration === 'MANDATORY') ||
      (application.isCustomerCloudCredentialsOnly && !application.cloudCredential) ||
      hasMissingRequiredConfigurationParameters;

    vm.free = $scope.estimate.priceExcludingVAT <= 0 && $scope.estimate.setupFee <= 0;
    vm.triable = application.productVersion.trial !== 'NOT_ALLOWED' || $scope.estimate.trialLength > 0;
    vm.ctaLabel = application.productVersion.metadata && application.productVersion.metadata.ctalabel;
    application.orderType = vm.triable ? 'TRIAL' : 'NORMAL';
    if (locked) $element.prop('disabled', true);
    else $element.prop('disabled', false);
  }

  let unlistenStartOrder = $scope.$on('start-order', (event, orderType) => {
    event.preventDefault();
    unlistenStartOrder();
    vm.startPlaceOrder(orderType);
  });

  let unlistenStartOrderAfterLogin = $scope.$on('open-place-order-dialog', (event) => {
    if ($rootScope.placeOrderDialogOpen) return;
    $rootScope.placeOrderDialogOpen = true;
    event.preventDefault();
    vm.startPlaceOrder();
    $timeout(() => $rootScope.placeOrderDialogOpen = false);
  });

  $scope.$on('$destroy', () => {
    unwatchVersion();
    unlistenStartOrder();
    unlistenStartOrderAfterLogin();
    unwatchEstimate();
  });
}

