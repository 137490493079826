
/* @ngInject */
export default function ($timeout) {
  return {
    restrict: 'A',
    link: onFinishRenderLink
  };

  function onFinishRenderLink(scope, el, attr) {
    if (scope.$last === true) {
      // digest cycle is really finished
      $timeout(function () {
        scope.$emit(attr.cdOnFinishRender);
      });
    }
  }
}
