/* @ngInject */
export default function () {
  return {
    restrict: 'E',
    require: 'ngModel',
    replace: true,
    template: '<a class="cd-checkbox" ng-class="{\'checked\': ngModel, \'disabled\': ngDisabled}"><input id="{{id}}" type="checkbox" style="display: none" ng-disabled="ngDisabled" ng-checked="ngModel"/></a>',
    scope: {
      id: '@',
      ngModel: '=',
      ngDisabled: '='
    },
    link: (scope, el) => {
      el.removeAttr('id');
      el.on('click', () => {
        scope.ngModel = !scope.ngModel;
        scope.$apply();
      });
      scope.$on('$destroy', () => el.off('click'));
    }
  };
}

