const loadCloudesireApp = () => angular.bootstrap(angular.element('#cdAppView'), ['cloudesireApp'], { strictDi: true });

const bootstrapModule = angular
  .module('cdBootstrap', ['cloudesire.common']);

bootstrapModule
  .config(($locationProvider, configurationServiceProvider) => {
    'ngInject';
    $locationProvider.html5Mode(configurationServiceProvider.isProduction());
  })
  .run(($window, $http, $location, Helpers, localStorageService, keycloakService) => {
    'ngInject';

    const hostname = $location.host();
    const hostnameRegex = /([a-z0-9\-]+)\./;
    const hostnameParts = hostnameRegex.exec(hostname);
    const profileName = hostnameParts && hostnameParts.length > 1 ? hostnameParts[1] : 'cloudesire';

    $window.config.default.profileName = profileName;
    $window.config.default.configurationFileName = hostname;

    const provider = localStorageService.get('token-provider');
    const keycloakProvider = provider === Helpers.AUTH_PROVIDERS.keycloak;

    keycloakService
      .init(keycloakProvider)
      .then(bootstrapMarketplace)
      .catch(bootstrapMarketplace);

    function getStorageUrl() {
      return fetch(`${$window.config.default.marketplaceApiUrl}/storage`)
        .then(response => response.json())
        .then(function(data) {
          $window.config.default.storageUrl = data;
        })
        .catch((error) => {
          console.warn('Cannot load storage url', error);
          $window.config.default.storageUrl = `${$window.config.default.gcloudStorageBaseUrl}/${$window.config.default.bucketName}/`;
        });
    }

    function bootstrapMarketplace(authenticated) {
      if (!authenticated && keycloakProvider) localStorage.removeItem('cd.token');

      getStorageUrl()
        .then(() => loadConfigurationFile(hostname)) // try the complete domain
        .catch(() => loadConfigurationFile(profileName)) // try the first part of the domain
        .finally(loadCloudesireApp);
    }

    function loadConfigurationFile(configurationFileName) {
      const configurationFile = `${$window.config.default.storageUrl}configuration.${configurationFileName}.json`;
      return $http
        .get(configurationFile)
        .then(res => angular.extend($window.config.default, res.data))
        .tap(() => injectTagManagerScript($window.config.default.tagManagerCode))
        .tap(() => injectAnalyticsScript($window.config.default.gaCode));
    }
  });

export default bootstrapModule;

function injectTagManagerScript(tagManagerCode) {
  if (_.isEmpty(tagManagerCode)) return;
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': // NOSONAR
        new Date().getTime(), event: 'gtm.js'
    }); var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s); j.async = true; j.src = // NOSONAR
        'https://www.googletagmanager.com/gtm.js?id=' + i; f.parentNode.insertBefore(j, f); // NOSONAR
  })(window, document, 'script', 'dataLayer', tagManagerCode);
}

function injectAnalyticsScript(gaCode) {
  if (_.isEmpty(gaCode)) return;
  /* jshint ignore:start */
  (function (i, s, o, g, r, a, m) {
    i.GoogleAnalyticsObject = r;
    i[r] = i[r] || function () { // NOSONAR
      (i[r].q = i[r].q || []).push(arguments); // NOSONAR
    }, i[r].l = 1 * new Date(); a = s.createElement(o), // NOSONAR
      m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m); // NOSONAR
  })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
  /* jshint ignore:end */
  window.ga('create', gaCode, 'auto');
}
