/* @ngInject */
export default function () {
  return {
    restrict: 'E',
    require: 'ngModel',
    replace: true,
    templateUrl: 'views/directive/cd-billing-item-tier.html',
    scope: {
      billingItemGroup: '=',
      mustShowProportionalSetup: '&',
    }
  };
}

