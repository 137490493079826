export default function () {
  var directive = {
    restrict: 'E',
    replace: true,
    controller: cdCountrySelectController,
    controllerAs: 'vm',
    scope: {
      address: '=',
      selectCountryCode: '@'
    },
    template: `
        <div class="cd-select-wrapper">
          <select
            required
            name="country"
            autocomplete="billing country-name"
            class="cd-select"
            ng-model="selectedCountry"
            ng-options="country.country for country in vm.countries">
          </select>
          <fa ng-click="triggerSelect()" name="angle-down" class="material-icons" />
        </div>
      `
  };
  return directive;

  /* @ngInject */
  function cdCountrySelectController(
    $scope,
    Restangular
  ) {
    const vm = this;

    const unwatchNgModel = $scope.$watch('selectedCountry', country => {
      if (!country) return;
      $scope.address.country = country.country;
      $scope.address.countryCode = country.countryCode;
    }, true);

    Restangular
      .one('countryCodes')
      .get()
      .then(countries => {
        vm.countries = countries;
        return countries;
      })
      .then(countries => {
        if ($scope.selectCountryCode)
          $scope.selectedCountry = _.find(countries, { countryCode: $scope.selectCountryCode });
      });

    $scope.$on('$destroy', unwatchNgModel);
  }
}

