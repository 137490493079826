/* @ngInject */
export default function () {
  var directive = {
    restrict: 'E',
    link: magnificPopupLink
  };
  return directive;

  function magnificPopupLink(scope, element, attrs) {
    var defaults = {};
    var options = angular.extend({}, defaults, scope.$eval(attrs.options));
    element.magnificPopup({
      delegate: options.selector,
      callbacks: {
        elementParse: item => {
          if (item.el.length > 0 && item.el[0].className === 'video') {
            item.type = 'iframe';
          } else {
            item.type = 'image';
          }
        },
        open: () => {
          angular.element('.mfp-wrap.mfp-gallery').removeAttr('tabindex');
        }
      },
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1]
      },
      zoom: {
        enabled: true,
        duration: 300, // duration of the effect, in milliseconds
        easing: 'ease-in-out', // CSS transition easing function
        opener: function (openerElement) {
          return openerElement.is('img') ? openerElement : openerElement.find('img');
        }
      },
      tLoading: 'Loading...',
      type: 'image'
    });
  }
}

