/* @ngInject */
export default function () {
  return {
    restrict: 'A',
    link: function ($scope, el) {
      const unwatch = $scope.$watch(() => el.height(), elHeight => {
        if (elHeight <= 0) return;
        if (elHeight > 0) unwatch();

        const parent = el.parent();
        const parentHeight = parent.height();
        const parentOffset = parent.offset().top;
        const elOffset = el.offset().top;
        const lineHeight = Math.round(parseFloat(el.css('line-height')));

        const heightAvailable = parentHeight - (elOffset - parentOffset);

        // means that el overflow
        if (heightAvailable < elHeight) {
          el.height(heightAvailable);
        }

        const maxLines = Math.round(heightAvailable / lineHeight).toString();
        el.css({ '-webkit-line-clamp': maxLines, 'max-lines': maxLines });
      });
    }
  };
}

