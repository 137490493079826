/* @ngInject */
export default function (
  product,
  company,
  apiFactory,
  productEditService,
  prerenderService) {
  const vm = this;

  vm.product = product;
  vm.company = company;
  vm.filteredProducts = [];
  vm.hideInfiniteScroll = true;
  vm.disableInfiniteScroll = false;
  vm.nextProductsPage = nextProductsPage;

  activate();

  ////////////////

  function activate() {
    nextProductsPage();
    prerenderService.pageReady();
    return apiFactory.getRels(vm.company, 'logoFile').then(function (company) {
      vm.company.logoFile = company.logoFile;
    });
  }

  function nextProductsPage() {
    var pagination;
    if (vm.filteredProducts.pagination) {
      pagination = vm.filteredProducts.pagination;
      pagination.pageNumber = parseInt(pagination.pageNumber) + 1;
    }
    searchProduct(pagination);
  }

  function searchProduct(pagination) {
    if (!pagination) pagination = { pageNumber: 1 };

    const params = { pageSize: 8, pageNumber: pagination.pageNumber, company: vm.company.id };
    return productEditService
      .getList(params)
      .then(products => {
        return Promise
          .map(products, product => product.initialize())
          .return(products);
      })
      .then(filteredProducts => {
        const pagination = filteredProducts.pagination;
        vm.disableInfiniteScroll = false;
        if (!vm.filteredProducts || vm.filteredProducts.length <= 0 || params.pageNumber === 1) vm.filteredProducts = filteredProducts;
        else vm.filteredProducts = vm.filteredProducts.concat(filteredProducts);
        vm.filteredProducts.pagination = pagination;
        vm.hideInfiniteScroll = parseInt(vm.filteredProducts.pagination.pageNumber) >= parseInt(vm.filteredProducts.pagination.totalPages);
        return vm.filteredProducts;
      });
  }
}
