import cloudesireLogo from '../../images/cloudesire-logo.png';
const FALLBACK_LOGO = { objectUrl: cloudesireLogo };

/* @ngInject */
export default function (
  $log,
  i18n,
  Restangular,
  FileService,
  applicationRatingService,
  UrlEntityService,
  featureService,
  configurationService
) {
  Restangular.extendModel('product', function (obj) {
    return angular.extend(obj, {
      initialize: initialize,
      partiallyInitialize: partiallyInitialize,
      initializeRandomRatings: initializeRandomRatings,
      initializeBundled: initializeBundled,
      initializeFiles: initializeFiles,
      initializeMetadata: initializeMetadata,
      initializeCategories: initializeCategories,
      initializeBestVersion: initializeBestVersion,
      initializeCompany: initializeCompany,
      initializeProviders: initializeProviders,
      getComments: getComments
    });
  });

  const productService = angular.extend(Restangular.service('product'), {
    retrieveCategories: retrieveCategories,
    partiallyInitializeAll: partiallyInitializeAll,
    retrieve: retrieve
  });

  return productService;

  ///////////////////////////////////////////////////////////////////////////

  function initializeBundled() {
    return featureService.getFeatureState('enabledProductTypes', 'BUNDLE')
      .then(_initializeBundled.bind(this))
      .catch(() => $log.info('Not retrieving bundles because the feature is disabled'));
  }

  function _initializeBundled() {
    const product = this;

    this.all('bundled').getList()
      .then(productBundleList => {
        product.productBundleList = productBundleList;
        _.map(_.flattenDeep(_.map(product.productBundleList, 'bundled')), bundled => {
          let product = bundled.product;
          applyRandomRating(product);
          initializeFiles.bind(product)();
          initializeCompany.bind(product)();
        });
      });
  }

  function initializeRandomRatings() {
    const product = this;
    return Promise.resolve(applyRandomRating(product)).return(product);
  }

  function initializeFiles() {
    const product = this;
    return FileService
      .retrieveAll(product.files)
      .tap(files => {
        product.files = _.sortBy(files, 'weight');
        product.logo = _.find(files, { tag: 'LOGO' }) || FALLBACK_LOGO;
        product.cover = _.find(files, { tag: 'COVER' });
        product.pdfs = _.filter(files, file => file.tag === 'PDF');
        product.screenshots = _.reject(files, file => file.tag === 'LOGO' || file.tag === 'COVER' || file.tag === 'PDF');
      })
      .return(product);
  }

  function initializeCategories() {
    const product = this;
    return UrlEntityService
      .retrieve(product.category)
      .tap(category => product.category = category)
      .catch(() => $log.warn('Product without category'))
      .return(product);
  }

  function initializeMetadata() {
    const product = this;

    return Restangular
      .all('product')
      .all(product.id)
      .one('metadata')
      .get()
      .then(metadata => {
        if (metadata.ribbon) {
          metadata.ribbon = JSON.parse(metadata.ribbon);
          metadata.ribbon.style = metadata.ribbon.useCustomColor && `background-color: ${metadata.ribbon.color}`;
        }
        product.metadata = metadata;
      })
      .return(product);
  }

  function initializeCompany() {
    const product = this;
    if (!product.company) return;

    return UrlEntityService
      .retrieve(product.company)
      .tap(company => product.company = company)
      .return(product);
  }

  function initializeBestVersion() {
    const product = this;
    let theBestVersion = product.recommendedVersion;
    if (!theBestVersion && product.minimumCost) theBestVersion = product.minimumCost.cheapest;

    return UrlEntityService
      .retrieve(theBestVersion)
      .tap(recommendedVersion => product.recommendedVersion = recommendedVersion)
      .return(product);
  }

  function initializeProviders() {
    const product = this;
    return UrlEntityService
      .retrieveList(product.cloudProviders)
      .tap(cloudProviders => product.cloudProviders = cloudProviders)
      .return(product);
  }

  function initialize() {
    const product = this;

    product.faq = product.faq || {};

    return Promise.all([
      product.initializeRandomRatings(),
      product.initializeFiles(),
      product.initializeCompany(),
      product.initializeCategories(),
      product.initializeMetadata(),
      product.initializeBundled(),
      product.initializeBestVersion().catch(() => $log.warn('Cannot initialize the best version')),
      product.initializeProviders(),
    ]).return(product);
  }

  function partiallyInitialize() {
    const product = this;

    product.dockerRibbon = !!_.find(product.tags, { tag: 'docker' });
    product.startupRibbon = !!_.find(product.tags, { tag: 'startup' });
    product.partnerRibbon = !!_.find(product.tags, { tag: 'partner' });
    product.companyRibbon = !!_.find(product.tags, { tag: 'company' });
    product.multiCloudRibbon = !!_.find(product.tags, { tag: 'multi-cloud' });
    product.devopsRibbon = !!_.find(product.tags, { tag: 'devops' });
    product.testRibbon = !!_.find(product.tags, { tag: 'test-ribbon' });

    return Promise.all([
      product.initializeRandomRatings(),
      product.initializeFiles(),
      product.initializeCompany(),
      product.initializeCategories(),
      product.initializeMetadata()
    ]).return(product);
  }

  function partiallyInitializeAll(products, async = false) {
    if (!async) return Promise.map(products, product => product.partiallyInitialize());

    _.map(products, product => product.partiallyInitialize());
    return Promise.resolve(products);
  }

  function applyRandomRating(product) {
    let randomRatingLimitReviews = parseInt(configurationService.getRandomRatingLimitReviews());
    let numberOfRatings = parseInt(product.numberOfRatings);
    let productId = parseInt(product.id);

    if (numberOfRatings < randomRatingLimitReviews) {
      product.averageRating = productId % 2 === 0 ? 4 : 5;
      product.numberOfRatings = productId % 4 + 1;
    } else {
      return applicationRatingService.retrieve(product.id, 1, 5)
        .then(comments => product.comments = comments);
    }
  }

  function retrieve(id, language = i18n.current()) {
    return this.one(id).get({
      language: language
    });
  }

  function retrieveCategories() {
    return Restangular.all('category')
      .getList()
      .then(categories => _.filter(categories, category => category.productCount > 0));
  }

  function getComments(params) {
    return Restangular.all('comment/product=' + this.id).getList(params);
  }
}
