const countryCodes = {
  'en': 'gb'
};

const codesCountry = {
  'gb': 'en'
};

/* @ngInject */
export default function (
  configurationService,
  i18n) {
  var directive = {
    restrict: 'E',
    templateUrl: 'views/directive/cd-marketplace-language-switcher.html',
    replace: false,
    controller: cdMarketplaceLanguageSwitcherController,
    controllerAs: 'vmLanguage'
  };
  return directive;

  /* @ngInject */
  function cdMarketplaceLanguageSwitcherController($scope) {
    var vmLanguage = this;
    vmLanguage.changeLanguage = changeLanguage;
    $scope.$on('language-changed', updateCurrentLanguage);

    updateCurrentLanguage();

    function updateCurrentLanguage() {
      var current = i18n.current();
      vmLanguage.currentLanguage = countryCodes[current] || current;
      populateLanguagesObject();
    }

    function populateLanguagesObject() {
      var languages = configurationService.getLanguages();
      if (languages.length <= 1) return;
      vmLanguage.languages = {};
      angular.forEach(languages, l => {
        var countryCode = countryCodes[l] || l;
        vmLanguage.languages[countryCode] = l;
      });
    }

    function changeLanguage(language) {
      language = codesCountry[language] || language;
      i18n.changeLanguage(language);
    }
  }
}

