/* @ngInject */
export default function (
  $rootScope,
  $scope,
  $state,
  $interval,
  Restangular,
  localStorageService
) {
  const toState = $rootScope.desiredState || 'marketplace.main';
  const toParams = $rootScope.desiredStateParams;

  let retryInterval;
  const globalCacheInvalidatorKey = 'globalCacheInvalidator';

  let globalCacheInvalidator = parseInt(localStorageService.get(globalCacheInvalidatorKey)) + 1;
  localStorageService.set(globalCacheInvalidatorKey, globalCacheInvalidator);

  const startInterval = () => {
    retryInterval = $interval(() => Restangular.one('version').get().then(() => $state.go(toState, toParams)), 3000);
  };

  const destroyInterval = () => {
    $interval.cancel(retryInterval);
  };

  startInterval();

  $scope.$on('$destroy', destroyInterval);
  $rootScope.$on('stop-error-retry', destroyInterval);
  $rootScope.$on('start-error-retry', startInterval);
}
