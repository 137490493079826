import fallbackCover from '../../images/fallback-cover.png';

/* @ngInject */
export default function (
  $scope,
  $location,
  $document,
  $timeout,
  $stateParams,
  $window,
  i18n,
  productEditService,
  prerenderService,
  hasFeaturedProducts,
  settingsService,
  configurationService
) {
  const vm = this;

  let searchObjectWatch;
  let languageChangedWatch;

  const defaultPageSize = 8;

  vm.fallbackCover = fallbackCover;
  vm.currentHref = $window.location.href;
  vm.currentHrefWithoutHl = removeParameterFromUrl(vm.currentHref, 'hl');
  vm.availableLanguages = configurationService.getAvailableLanguages();
  vm.currentLanguage = i18n.current();
  vm.alternateLang = _.find(vm.availableLanguages, l => l !== vm.currentLanguage);
  vm.hasFeaturedProducts = hasFeaturedProducts;
  vm.featuredProducts = void 0;
  vm.filteredProducts = void 0;
  vm.categories = void 0;
  vm.nextProductsPage = nextProductsPage;
  vm.hideInfiniteScroll = true;
  vm.disableInfiniteScroll = false;
  vm.pageReady = prerenderService.pageReady;
  vm.searchObject = {
    pageSize: defaultPageSize,
    pageNumber: 1,
    nameFilter: '',
    categoryId: $stateParams.category,
    orderingType: 'RECOMMENDED',
    language: i18n.current()
  };

  activate();

  ////////////////

  function activate() {
    const settings = settingsService.getSettingsSync();
    if (!settings) {
      settingsService.getSettings()
        .then(extractSettings)
        .finally(() => loadProducts());
    } else {
      extractSettings(settings);
      loadProducts();
    }

    productEditService
      .retrieveCategories()
      .then(categories => vm.categories = categories);
  }

  function extractSettings(settings) {
    const getMinimumProducts = settings =>
      (settings.homepageProductsCount || vm.searchObject.pagesize);
    vm.searchObject.pageSize = getMinimumProducts(settings);
    vm.favicon = settings.favicon;
    vm.maxElements = settings.homepageCategoriesCount || 3;
    vm.partnersBanner = settings.partnersBanner;
  }

  function loadProducts(language = vm.currentLanguage) {
    searchObjectWatch = searchObjectWatch || $scope.$watch('vm.searchObject', prepareSearch, true);
    languageChangedWatch = languageChangedWatch || $scope.$on('language-changed', (ev, language) => loadProducts(language));

    if ($stateParams.q || $stateParams.category) {
      $scope.$on('cd-carousel-loaded', () => $timeout(() =>
        $document.scrollToElementAnimated(angular.element(document.getElementById('middle-section')), -20)
      ), 50);
    }

    vm.searchObject.nameFilter = $stateParams.q || '';
    vm.searchObject.language = language;

    productEditService
      .getList({
        featured: true,
        orderingType: 'RECOMMENDED',
        language: i18n.current(),
      })
      .then(products => productEditService.partiallyInitializeAll(products, false))
      .then(featuredProducts => vm.featuredProducts = featuredProducts);
  }

  function nextProductsPage() {
    vm.disableInfiniteScroll = true;
    vm.searchObject.pageNumber = parseInt(vm.searchObject.pageNumber) + 1;
  }

  function prepareSearch(searchObject, oldSearchObject) {
    var oldName = oldSearchObject.nameFilter;
    var oldCategory = oldSearchObject.categoryId;
    var oldOrderingType = oldSearchObject.orderingType;
    var newName = searchObject.nameFilter;
    var newCategory = searchObject.categoryId;
    var newOrderingType = searchObject.orderingType;
    if (oldName !== newName || oldCategory !== newCategory || oldOrderingType !== newOrderingType) {
      vm.searchObject.pageNumber = 1;
    }

    searchProduct(searchObject, 1);
  }

  function searchProduct(searchObject, pageNumber) {
    var params = { pageNumber: pageNumber };
    angular.extend(params, searchObject);
    if (searchObject.nameFilter.charAt(0) === '#') {
      let matches = params.nameFilter.split(/[ ](.+)/);
      params.tag = matches.length >= 1 ? matches[0].replace('#', '') : void 0;
      params.nameFilter = matches.length >= 2 ? matches[1].trim() : '';
    }

    if ('' !== vm.searchObject.nameFilter) $location.search('q', params.nameFilter);
    else $location.search('q', null);

    if (params.pageNumber > 1) params.pageSize = vm.searchObject.pageSize;

    return productEditService
      .getList(params)
      .then(products => productEditService.partiallyInitializeAll(products, true))
      .then(filteredProducts => {
        const pagination = filteredProducts.pagination;
        vm.disableInfiniteScroll = false;
        if (!vm.filteredProducts || vm.filteredProducts.length <= 0 || params.pageNumber === 1) vm.filteredProducts = filteredProducts;
        else vm.filteredProducts = vm.filteredProducts.concat(filteredProducts);
        vm.filteredProducts.pagination = pagination;
        vm.hideInfiniteScroll = parseInt(vm.filteredProducts.pagination.pageNumber) >= parseInt(vm.filteredProducts.pagination.totalPages);
        return vm.filteredProducts;
      }
      );
  }

  angular.element('#breadcrumb').hide();
  $scope.$on('$destroy', () => {
    angular.element('#breadcrumb').fadeIn();
    if (searchObjectWatch) searchObjectWatch();
    if (languageChangedWatch) languageChangedWatch();
  });
}

function removeParameterFromUrl(url, parameter) {
  return url
    .replace(new RegExp('[?&]' + parameter + '=[^&#]*(#.*)?$'), '$1')
    .replace(new RegExp('([?&])' + parameter + '=[^&]*&'), '$1');
}
