/* @ngInject */
export default function (urlParser) {
  return function (videoUrl) {

    const RE_YOUTUBE = /^(?:\/embed)?\/([\w-]{10,12})$/;

    let url = videoUrl || '';

    const urlobj = urlParser.parse(url);

    //youtube
    if (['www.youtube.com', 'youtube.com', 'youtu.be'].indexOf(urlobj.host) !== -1) {
      let videoId = null;
      if ('v' in urlobj.searchObject) {
        if (urlobj.searchObject.v && urlobj.searchObject.v.match(/^[\w-]{10,12}$/)) {
          videoId = urlobj.searchObject.v;
        }
      } else {
        const match = RE_YOUTUBE.exec(urlobj.pathname);
        if (match) {
          videoId = match[1];
        }
      }

      if (videoId) {
        return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
      }
    }

    return 'https://placehold.it/1/000000';
  };
}
