import cdSettings from './cd-settings';
import cdVideoThumbnail from './cd-video-thumbnail';
import ellipsis from './ellipsis';
import htmlToPlainText from './html-to-plain-text';

const filterModule = angular.module('cloudesireApp.filter', []);

filterModule.filter('cdSettings', cdSettings);
filterModule.filter('cdVideoThumbnail', cdVideoThumbnail);
filterModule.filter('ellipsis', ellipsis);
filterModule.filter('htmlToPlainText', htmlToPlainText);

export default filterModule;
