/* @ngInject */
export default function (
  $analytics,
  Restangular,
  notify,
  configurationService
) {
  this.deployObject = function ({
    productVersion,
    orderType,
    cloudProvider,
    cloudCredential,
    bandwidthPricing,
    configurationParameters,
    billingItems,
    hashCoupon,
    agentCode,
    operatingSystem,
    externalReference
  }) {
    return this.deploy(
      productVersion,
      orderType,
      cloudProvider,
      cloudCredential,
      bandwidthPricing,
      configurationParameters,
      billingItems,
      hashCoupon,
      agentCode,
      operatingSystem,
      externalReference
    );
  };

  function getBandwidthPricing(cloudProvider) {
    if (angular.isUndefined(cloudProvider)) return Promise.resolve();

    return Restangular
      .all('bandwidthPricing')
      .getList({ cloudProvider: cloudProvider.id })
      .then(bandwidthPricings => bandwidthPricings[0]);
  }

  this.deploy = function (
    productVersion,
    orderType,
    cloudProvider,
    cloudCredential,
    bandwidthPricing,
    configurationParameters,
    billingItems,
    hashCoupon,
    agentCode,
    operatingSystem,
    externalReference
  ) {
    const order = {};
    order.type = orderType;
    order.cloudProvider = cloudProvider;
    order.cloudCredential = cloudCredential;
    order.productVersion = productVersion;
    order.hashCoupon = hashCoupon;
    order.configurationParameters = configurationParameters;
    order.billingItems = billingItems;
    order.operatingSystem = operatingSystem;
    order.externalReference = externalReference;

    return (
      angular.isUndefined(bandwidthPricing) ?
        getBandwidthPricing(order.cloudProvider) :
        Promise.resolve(bandwidthPricing)
    )
      .then(bandwidthPricing => {
        order.bandwidthPricing = bandwidthPricing;

        const isParentMarketplace = configurationService.isParentMarketplace();
        const ownerUsername = configurationService.getOwnerUsername();

        order.reseller = configurationService.isResellerChildMarketplace() ? ownerUsername : null;
        order.reseller = isParentMarketplace ? agentCode : order.reseller;
        order.distributor = configurationService.isDistributorChildMarketplace() ? ownerUsername : null;

        return Restangular.all('order')
          .post(order)
          .tap(() => notifyToAnalytics(order))
          .catch(e => {
            if (e.status === 429) {
              notify.error('You have reached the max number of active deployments');
            } else throw e;
          });
      });
  };

  function notifyToAnalytics(order) {
    const category = 'orders';
    const action = order.type.toLowerCase();
    const label = order.productVersion.id;
    const eventProperties = {
      category: category,
      label: label,
      nonInteraction: true
    };

    $analytics.eventTrack(action, eventProperties);
  }
}
