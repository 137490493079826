/* @ngInject */
export default function (Restangular) {
  const cloudProviderService = angular.extend(Restangular.service('cloudProvider'), {
    getAll: getAll
  });

  return cloudProviderService;

  function getAll(productType) {
    return this.getList({ productType: productType });
  }
}
