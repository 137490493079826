/* @ngInject */
export default function (Restangular, i18n, featureService) {
  return angular.extend(Restangular.service('comment'), {
    retrieve: retrieve
  });

  function retrieve(id, pageNumber, pageSize, language = i18n.current()) {
    let params = {
      language: language,
      pageNumber: pageNumber,
      pageSize: pageSize
    };

    return featureService.getFeatureState('customerProductComments', true)
      .catch(() => false)
      .then(customerProductComments => {
        if(!customerProductComments) return Promise.resolve([]);
        return Restangular.all('comment?productId=' + id).getList(params);
      });
  }
}
