import authService from './auth';
import deployService from './deploy';
import entityToUrlService from './entity-to-url';

const serviceModule = angular.module('cloudesireApp.service', []);

serviceModule.service('authService', authService);
serviceModule.service('deployService', deployService);
serviceModule.service('entityToUrlService', entityToUrlService);

export default serviceModule;
