/* @ngInject */
export default function($interval) {
  const notFoundMetaTag = angular.element('<meta name="prerender-status-code" content="404" />');
  const redirectMetaTag = angular.element('<meta name="prerender-status-code" content="301" />');
  const headEl = angular.element('head');

  return {
    pageReady: () => {
      let marketplace = angular.element('#marketplace');
      let interval = $interval(() => {
        if (marketplace.hasClass('show-in')) {
          $interval.cancel(interval);
          window.prerenderReady = true;
        }
      }, 500);
    },
    set404: () => headEl.append(notFoundMetaTag.clone()),
    set301: url => {
      const locationHeader =
        angular.element(`<meta name="prerender-header" content="Location: ${url}">`);
      headEl.append(redirectMetaTag.clone());
      headEl.append(locationHeader);
    },
    removeMetas: () => {
      angular.element('meta[name="prerender-status-code"]').remove();
      angular.element('meta[name="prerender-header"]').remove();
    },
  };
}
