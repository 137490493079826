/* @ngInject */
export default function (Restangular) {
  Restangular.extendModel('budgetEstimate', function (obj) {
    return angular.extend(obj, {
      initialize: initialize
    });
  });

  return angular.extend(Restangular.service('budgetEstimate'), {});

  function initialize() {
    this.setupFee = this.firstPriceExcludingVAT - this.priceExcludingVAT;
    return this;
  }
}
