/* @ngInject */
export default function (
  $log,
  $analytics,
  UrlEntityService,
  Restangular,
  i18n,
  stripeService,
  notify,
  configurationService
) {
  Restangular.extendModel('user', function (obj) {
    return angular.extend(obj, {
      attachCreditCard: attachCreditCard,
      attachSepa: attachSepa,
      initializeCompany: initializeCompany
    });
  });

  const userService = angular.extend(Restangular.service('user'), {
    create: create,
    retrieve: retrieve,
    pingAnalytics: pingAnalytics,
    normalizeRole: normalizeRole,
    analyticsSessionUnification: analyticsSessionUnification
  });

  return userService;

  function retrieve(id, language) {
    return this.one(id).get({
      language: language
    }).then(user => user.initializeCompany().return(user));
  }

  function create(user, role) {
    user.userRole = role;
    user.userName = user.userName || user.email;
    user.language = i18n.current();
    user.sendActivationEmail = false;
    return userService.post(user)
      .tap(() => pingAnalytics('created', normalizeRole(user.userRole)));
  }

  function pingAnalytics(eventName, label, nonInteraction = true) {
    $analytics.eventTrack(eventName, {
      category: 'users',
      label: label,
      nonInteraction: nonInteraction
    });
  }

  function initializeCompany() {
    const user = this;

    if (!user.company) return Promise.resolve(user);
    else return UrlEntityService
      .retrieve(user.company)
      .then(c => user.company = c);
  }

  function attachCreditCard(card) {
    const user = this;
    if (user.paymentDataSaved) return Promise.resolve(user);

    return new Promise((resolve, reject) => {
      const stripe = stripeService.getStripe();

      stripe.createPaymentMethod('card', card, {
        billing_details: { // jshint ignore:line
          name: user.fullName,
        },
        metadata: {
          environment: configurationService.getCmwEnvironment(),
          userId: user.id
        }
      })
        .then(result => {
          if (result.error) {
            notify.error(result.error.message);
            reject(result.error);
          } else {
            Restangular.all('paymentMethod')
              .post({
                label: 'card',
                payload: { paymentMethodId: result.paymentMethod.id }
              })
              .tap(() => pingAnalytics('cardAttached'))
              .then(resolve)
              .catch(reject);
          }
        })
        .catch(error => {
          $log.error(error);
          reject(error);
        });
    });
  }

  function attachSepa(iban) {
    const user = this;
    if (user.paymentDataSaved) return Promise.resolve(user);

    return configurationService
      .getCmwEnvironmentObject()
      .then(environment => {
        const currency = environment.configuration.currency;
        const sourceData = {
          type: 'sepa_debit',
          currency: currency,
          owner: { name: user.fullName }
        };

        const stripe = stripeService.getStripe();

        stripe
          .createSource(iban, sourceData)
          .then(result => {
            if (result.error) {
              return Promise.reject(result.error.message);
            } else {
              return Restangular.all('paymentMethod')
                .post({
                  label: 'iban',
                  payload: { stripeToken: result.source.id }
                })
                .tap(() => pingAnalytics('ibanAttached'));
            }
          });
      });
  }

  function normalizeRole(userRole) {
    return userRole.toLowerCase().replace('role_', '');
  }

  function analyticsSessionUnification(id) {
    try {
      $analytics.setUsername(id);
      /* jshint ignore:start */
      ga('set', 'userId', id);
      /* jshint ignore:end */
    } catch (e) {
      $log.error('Cannot unify analytics sessions', e);
    }
  }
}
