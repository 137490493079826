/* @ngInject */
export default function MarketPlaceTagCtrl(
  $scope,
  $stateParams,
  i18n,
  productEditService,
  prerenderService
) {
  const vm = this;

  vm.filteredProducts = void 0;
  vm.nextProductsPage = nextProductsPage;
  vm.currentLanguage = i18n.current();
  vm.pageReady = prerenderService.pageReady;
  vm.tag = $stateParams.tag.toLowerCase();
  vm.searchObject = {
    pageSize: 8,
    pageNumber: 1,
    nameFilter: '',
    tag: $stateParams.tag,
    orderingType: 'RECOMMENDED'
  };

  searchProduct(vm.searchObject, 1);

  let unwatchLanguage = $scope.$on('language-changed', (ev, language) => vm.currentLanguage = language);

  ////////////////

  function nextProductsPage() {
    vm.disableInfiniteScroll = true;
    vm.searchObject.pageNumber = parseInt(vm.searchObject.pageNumber) + 1;
    searchProduct(vm.searchObject);
  }

  function searchProduct(searchObject, pageNumber) {
    var parameters = { pageNumber: pageNumber };
    angular.extend(parameters, searchObject);

    return productEditService
      .getList(parameters)
      .then(products => productEditService.partiallyInitializeAll(products, true))
      .then(filteredProducts => {
        const pagination = filteredProducts.pagination;
        vm.disableInfiniteScroll = false;
        if (!vm.filteredProducts || vm.filteredProducts.length <= 0 || parameters.pageNumber === 1) vm.filteredProducts = filteredProducts;
        else vm.filteredProducts = vm.filteredProducts.concat(filteredProducts);
        vm.filteredProducts.pagination = pagination;
        vm.hideInfiniteScroll = parseInt(vm.filteredProducts.pagination.pageNumber) >= parseInt(vm.filteredProducts.pagination.totalPages);
        return vm.filteredProducts;
      })
      .finally(() => vm.filteredProducts.length > 0 ? prerenderService.removeMetas() : prerenderService.set404());
  }

  $scope.$on('$destroy', unwatchLanguage);
}
