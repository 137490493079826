let originalOffset;
let originalHeight;

/* @ngInject */
export default function ($timeout, $mdMedia, $window) {
  let directive = {
    restrict: 'A',
    link: cdFixedLink
  };

  return directive;

  function cdFixedLink(scope, element) {
    $timeout(() => {
      originalHeight = element.height();
      originalOffset = angular.element(element).offset().top + originalHeight;
      angular.element(document).on('scroll', calculatePosition);
      scope.$on('$destroy', () => angular.element(document).off('scroll', calculatePosition));

      function calculatePosition() {
        if ($mdMedia('sm') || $mdMedia('xs')) return;
        if (originalOffset <= $window.scrollY) {
          element.addClass('fixed');
          angular.element(element).next().css('margin-top', `${originalHeight * 2}px`);
        } else {
          element.removeClass('fixed');
          angular.element(element).next().css('margin-top', '0px');
        }
      }
    });
  }
}

