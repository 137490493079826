/* @ngInject */
export default function (
  i18n,
  Restangular,
  UrlEntityService
) {
  Restangular.extendModel('productVersion', function(obj) {
    return angular.extend(obj, {
      initialize: initialize,
      initializeBillingItems: initializeBillingItems,
      refreshBillingItems: refreshBillingItems
    });
  });

  const itemValueTypes = {
    payAsYouGo: 'PAY_AS_YOU_GO',
    prePaid: 'PRE_PAID',
    postPaid: 'POST_PAID'
  };

  const productVersionService = angular.extend(Restangular.service('productVersion'), {
    retrieve: retrieve,
    createEmptyVersion: createEmptyVersion,
    retrieveAllByProduct: retrieveAllByProduct,
    retrieveBillingItems: retrieveBillingItems,
    retrieveConfigurationParameters: retrieveConfigurationParameters,
    collectBillingItems: collectBillingItems,
    collectConfigurationParameters: collectConfigurationParameters,
  });

  return productVersionService;

  function retrieve(id, language = i18n.current(), initialize = true) {
    return this.one(id).get({
      language: language
    }).then(productVersion => {
      productVersion = productVersionConverter(productVersion);
      if(initialize) return productVersion.initialize();
      else return Promise.resolve(productVersion);
    });
  }

  function productVersionConverter(pv) {
    const productVersion = Restangular.copy(pv);

    productVersion.triable = productVersion.trial !== 'NOT_ALLOWED' &&
      productVersion.lifespan &&
      productVersion.lifespan !== 0;

    return productVersion;
  }

  function retrieveAllByProduct(productId, withUnpublished, shouldInitialize = true, language = i18n.current()) {
    return this.getList({
      language: language,
      product: productId,
      withUnpublished: withUnpublished
    }).then(versions => shouldInitialize ? initializeAll(versions, language) : versions);
  }

  function createEmptyVersion() {
    let productVersion = Restangular.restangularizeElement(null, {}, 'productVersion');
    return productVersion.initialize();
  }

  function initializeAll(productVersions, language) {
    return Promise.all(
      _.map(productVersions, produtVersion => {
        return produtVersion.initialize(language);
      })
    );
  }

  function initialize(language = i18n.current()) {
    let productVersion = this;

    if(angular.isObject(productVersion.metadata) && angular.isObject(productVersion.metadata[language])) {
      productVersion.metadata = productVersion.metadata[language];
    }

    let billingItemValuesPromises = Promise.all(
      _.map(productVersion.billingItemValues, billingItemValue =>
        UrlEntityService.retrieve(billingItemValue.item)
          .then(billingItem => billingItemValue.item = billingItem)
          .return(billingItemValue)
      )
    );

    let bundlePromises = Promise.map(_.keys(productVersion.bundle), bundleVersion => {
        productVersion.bundleVersions = productVersion.bundleVersions || [];
        let productVersionId = UrlEntityService.toId({url: bundleVersion});
        return productVersionService.retrieve(productVersionId)
          .then(bundleVersionInitialized => {
            bundleVersionInitialized.discount = productVersion.bundle[bundleVersion];
            productVersion.bundleVersions.push(bundleVersionInitialized);
          });
      }
    ).then(() => {
      _.map(productVersion.bundleVersions, version => {
        UrlEntityService.retrieve(version.product)
          .then(product => {
            version.productName = product.name;
            version.productType = product.type;
          });
        initializeBillingItems.bind(version)();
      });
    });

    let cloudProvidersPromise = Promise.resolve();
    if(productVersion.cloudProviders) {
      cloudProvidersPromise = UrlEntityService
        .retrieveList(productVersion.cloudProviders)
        .then(cloudProviders => productVersion.cloudProviders = cloudProviders);
    }

    return Promise.all([
      billingItemValuesPromises,
      bundlePromises,
      cloudProvidersPromise
    ])
    .return(productVersion);
  }

  function retrieveBillingItems() {
    return Restangular.all('billingItem')
      .getList({sortField: 'id', pageSize: 50});
  }

  function groupByItem(billingItemValues) {
    const groups = _.groupBy(billingItemValues, billingItemValue => billingItemValue.item.self);
    for(let key in groups) {
      if(groups.hasOwnProperty(key)) {
        groups[key] = new BillingItemsGroup(groups[key]);
      }
    }

    return groups;
  }

  function retrieveBillingItem(billingItemValue) {
    return UrlEntityService.retrieve(billingItemValue.item)
      .then(billingItem => {
        billingItemValue.item = billingItem;
        return billingItemValue;
      });
  }

  function refreshBillingItems() {
    const productVersion = this;
    _.forEach(_mergeBillingItemGroups(productVersion), group => {
      group.quantity = void 0;
      group.include = void 0;

      _commonBillingItemInitialize(group);
    });
  }

  function initializeBillingItems() {
    const productVersion = this;

    if(productVersion.billingItemClassicPrepaidGroups) return Promise.resolve();
    if(productVersion.billingItemValues.length <= 0) return Promise.resolve();

    return Promise.all(
      _.map(productVersion.billingItemValues, (billingItemValue) => retrieveBillingItem(billingItemValue))
    )
      .then(_sortByEndpoint)
      .then(billingItemValues => {
        // split by billing item value type
        const [payAsYouGoItemValues, prepaidItemValues, postpaidItemValues] = _splitByItemValueType(billingItemValues);
        // split pay as you go api from classic billing items
        const [apiPayAsYouGoItemValues, classicPayAsYouGoItemValues] = _splitApiAndClassic(payAsYouGoItemValues);
        // split prepaid api from classic billing items
        const [apiPrepaidItemValues, classicPrepaidItemValues] = _splitApiAndClassic(prepaidItemValues);
        // split postpaid api from classic billing items
        const [apiPostpaidItemValues, classicPostpaidItemValues] = _splitApiAndClassic(postpaidItemValues);
        // attach to product version and initialize
        productVersion.billingItemApiPayAsYouGoGroups = groupByItem(apiPayAsYouGoItemValues);
        productVersion.billingItemClassicPayAsYouGoGroups = groupByItem(classicPayAsYouGoItemValues);
        productVersion.billingItemApiPrepaidGroups = groupByItem(apiPrepaidItemValues);
        productVersion.billingItemClassicPrepaidGroups = groupByItem(classicPrepaidItemValues);
        productVersion.billingItemApiPostpaidGroups = groupByItem(apiPostpaidItemValues);
        productVersion.billingItemClassicPostpaidGroups = groupByItem(classicPostpaidItemValues);
        _.forEach(_mergeBillingItemGroups(productVersion), group => {
          _commonBillingItemInitialize(group);
        });
      })
      .return(productVersion);
  }

  function _splitByItemValueType(billingItemValues) {
    const groupsByValueType = _.groupBy(billingItemValues, 'item.valueType');
    return [
      groupsByValueType[itemValueTypes.payAsYouGo],
      groupsByValueType[itemValueTypes.prePaid],
      groupsByValueType[itemValueTypes.postPaid]
    ];
  }

  function _splitApiAndClassic(billingItemValues) {
    return _.partition(billingItemValues, obj => obj.item.endpoint);
  }

  function _sortByEndpoint(billingItemValues) {
    return _.sortBy(billingItemValues, biv => biv.item.endpoint);
  }

  function _commonBillingItemInitialize(group) {
    let firstBillingItemValue = _.first(group);

    group.type = firstBillingItemValue.item.type;
    group.include = group.include || ['REQUIRED', 'RECOMMENDED'].includes(firstBillingItemValue.item.presence);
    group.switchDisabled = (firstBillingItemValue.item.presence === 'REQUIRED');
    group.quantity = group.quantity || firstBillingItemValue.start;

    const start = firstBillingItemValue.start;
    const end = group[group.length-1].end;
    const step = (start && end) ? (end-start)/10 : void 0;
    group.step = Math.round(step) || 1;
  }

  function collectBillingItems(productVersion) {
    let billingItemValues = {};

    _.each(_mergeBillingItemGroups(productVersion), (billingItem, key) => {
      if(billingItem.quantity >= 0 && billingItem.include) {
        billingItemValues[key] = billingItem.quantity;
      }
    });

    return billingItemValues;
  }

  function retrieveConfigurationParameters(configurationParametersFromProduct, productVersion, chosenConfigurationParameters, buyerId, cloudCredential) {
    const configurationParameterCopy = angular.copy(productVersion.configurationParameters);
    const configurationParameterOnProductVersion = _.map(configurationParameterCopy, configurationParameter =>
      _.find(configurationParametersFromProduct, { self: configurationParameter.url || configurationParameter.self })
    );

    return Promise.map(configurationParameterOnProductVersion, configurationParameter => {
      // we don't need to fetch values
      if (!configurationParameter.externalValuesUrl) return Promise.resolve(configurationParameter);

      // we must fetch values
      const queryParams = {
        language: i18n.current(),
      };

      const body = {
        productVersion,
        configurationParameters: chosenConfigurationParameters,
        buyerId,
        cloudCredential,
      };

      return Restangular
        .one(`${(configurationParameter.self || configurationParameter.url)}/values`)
        .post(null, body, queryParams)
        .then(response => {
          const acceptedValues = _.isNil(response.values) ? void 0 : {};
          _.forEach(response.values, obj => {
            acceptedValues[obj.value] = obj.description;
          });
          configurationParameter.acceptedValues = acceptedValues;
          return configurationParameter;
        });
    })
      .then(configurationParameters => {
        // acceptedValues = {} should not be displayed, acceptedValues = null all values are accepted
        configurationParameters = _.reject(configurationParameters, configurationParameter =>
          !_.isNil(configurationParameter.acceptedValues) && _.isEmpty(configurationParameter.acceptedValues)
        );

        productVersion.requiredConfigurationParameters = _.filter(configurationParameters, configurationParameter =>
          configurationParameter.required
        );

        productVersion.hasRequiredConfigurationParameters = productVersion.requiredConfigurationParameters.length > 0;

        return configurationParameters;
      });
  }

  function collectConfigurationParameters(configurationParameters) {
    if(_.isEmpty(configurationParameters)) return;
    const cp = {};
    _.each(configurationParameters, configurationParameter => {
      if (!_.isNil(configurationParameter.value)) {
        cp[configurationParameter.self] = configurationParameter.value;
      }
    });

    return cp;
  }

  function _mergeBillingItemGroups(productVersion) {
    return Object.assign({},
      productVersion.billingItemApiPayAsYouGoGroups,
      productVersion.billingItemClassicPayAsYouGoGroups,
      productVersion.billingItemApiPrepaidGroups,
      productVersion.billingItemClassicPrepaidGroups,
      productVersion.billingItemApiPostpaidGroups,
      productVersion.billingItemClassicPostpaidGroups
    );
  }
}

function BillingItemsGroup(group) {
  let _include;
  let _quantity;
  let _type;

  if(group) this.push.apply(this, group);

  Object.defineProperty(
    this, 'include',
    {
      enumerable: true,
      get: function() { return _include; },
      set: function(value) { _include = value; }
    }
  );

  Object.defineProperty(
    this, 'quantity',
    {
      enumerable: true,
      get: function() { return _quantity; },
      set: function(value) { _quantity = value; }
    }
  );

  Object.defineProperty(
    this, 'type',
    {
      enumerable: true,
      get: function() { return _type; },
      set: function(value) { _type = value; }
    }
  );
}

BillingItemsGroup.prototype = Array.prototype;
