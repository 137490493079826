export default function () {
  return {
    restrict: 'E',
    require: 'ngModel',
    replace: true,
    template: '<div class="cd-radio" layout="row">' +
      '<input id="{{index}}" type="radio" ng-model="ngModel" ng-value="ngValue" />' +
      '<label class="pointer" for="{{index}}"></label>' +
      '</div>',
    scope: {
      checked: '@',
      index: '@',
      ngValue: '=',
      ngModel: '='
    }
  };
}

