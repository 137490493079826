/* @ngInject */
export default function (Restangular, i18n) {
  return {
    fromEntity: fromEntity,
    retrieveList: retrieveList,
    retrieve: retrieve,
    toId: toId
  };

  function retrieveList(urlEntityList) {
    return Promise.all(_.map(urlEntityList, retrieve));
  }

  function retrieve(urlEntity, skipError) {
    const queryParams = {
      language: i18n.current()
    };
    if(skipError) queryParams.skipError = skipError;
    return Promise.try(() => Restangular.one(urlEntity.url || urlEntity.self).get(queryParams));
  }

  function toId(urlEntity) {
    if (!urlEntity || !urlEntity.url) throw new Error('Not a valid url entity');
    let matches = /.*\/(\d+)/g.exec(urlEntity.url);
    if (matches.length >= 1) return matches[1];
    return void 0;
  }

  function fromEntity(entity) {
    if (!entity || !entity.self) throw new Error('Not a valid entity');
    return { url: entity.self };
  }
}
