/* @ngInject */
export default function (
  $scope,
  $rootScope,
  $mdSidenav,
  $state,
  $stateParams,
  $location,
  $timeout,
  $window,
  i18n,
  configurationService,
  notify,
  currentUser,
  UrlEntityService,
  settingsService,
  localStorageService,
  platformMessagesEditService,
  featureService
) {
  const vm = this;
  vm.currentLanguage = i18n.current();
  vm.noEmptyLinks = links => _.some(links, link => link.anchor && link.url);
  vm.toggleSideNav = () => {
    let sidenavElement = $mdSidenav('cd-reseller-sidenav');
    sidenavElement.toggle();
    if (sidenavElement.isOpen())
      angular.element('#marketplace').addClass('slide-right');
  };

  featureService.isEnabled('ldap')
    .then(() => {
      $scope.ldapEnabled = true;
      $window.skipLdapLogin = $window.skipLdapLogin || false;
    })
    .catch(() => {
      $scope.ldapEnabled = false;
      $window.skipLdapLogin = true;
    });

  settingsService
    .getSettings()
    .then(settings => vm.cookieConsentHref = settings.cookieConsentHref);

  let showMarketplace = () => {
    angular.element('#marketplace').addClass('show-in');
    angular.element('#cd-global-progress').addClass('show-in');
    angular.element('#loading-bar').remove();
  };

  $scope.currentUserObject = currentUser;
  $scope.currentUser = currentUser.user;
  $scope.currentYear = new Date().getFullYear();
  $scope.goToProduct = product => $state.go('marketplace.product',
    {
      product: product.id,
      productSlug: product.sluggedName,
      categorySlug: product.category && product.category.sluggedName ? product.category.sluggedName : ''
    }
  );
  $scope.goToHome = () => $state.go('marketplace.main');
  $scope.controlPanelDashboardUrl = configurationService.getControlPanelDashboardUrl();
  $scope.controlPanelRegisterAsVendorUrl = configurationService.getControlPanelRegisterAsVendorUrl();
  $scope.controlPanelLoginUrl = configurationService.getControlPanelLoginUrl();
  $scope.isDistributorChildOrParent = configurationService.isDistributorChildMarketplace() || configurationService.isParentMarketplace();

  $scope.$watch('currentUserObject.isLogged', isLogged => {
    $scope.logged = isLogged;
    $scope.currentUser = currentUser.user;

    if (!isLogged) {
      localStorageService.remove('features');
    } else {
      let companyPromise = Promise.resolve(void 0);
      if (currentUser.isReseller() || currentUser.isDistributor()) {
        companyPromise = UrlEntityService.retrieve(currentUser.user.company);
      }

      companyPromise.then(company => {
        const isTheOwner = settingsService.isTheOwner(currentUser.user.userName) ||
          (angular.isObject(company) && settingsService.isTheOwner(company.slug)) ||
          (angular.isObject(company) && settingsService.isTheOwner(company.marketplaceDomain));
        vm.showResellerSidenav = currentUser.isAdministrator() || (configurationService.isChildMarketplace() && isTheOwner);
      });
    }
  });

  platformMessagesEditService.retrieveAll()
    .then(messages => vm.platformMessages = messages);

  if (!configurationService.isProduction()) $timeout(showMarketplace);
  if ($stateParams.hl) i18n.changeLanguage($stateParams.hl);

  $scope.$on('theme-loaded', showMarketplace);
  $scope.$on('language-changed', (ev, language) => vm.currentLanguage = language);

  notify.popAllMessages();

  $location.search('state', null);
  $location.search('session_state', null);
  $location.search('code', null);
  $location.search('error', null);
}
