'use strict';

export default function () {
  return function (text) {
    if (text === undefined) {
      return '';
    }
    return String(text).replace(/<[^>]+>/gm, '');
  };
}
