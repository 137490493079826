const defaultSkin = 'blue';

/* @ngInject */
export default function (settingsService, $http) {
  let fileRev;

  let directive = {
    restrict: 'E',
    link: cdCustomThemeLink
  };

  return directive;

  function cdCustomThemeLink(scope) {
    let refreshTheme = (ev, skin) => {
      if (skin) appendThemeLink(skin, scope);
      else activate();
    };

    let deleteRefreshEvent = scope.$on('refresh-theme', refreshTheme);
    let languageChangedEvent = scope.$on('language-changed', () => refreshTheme());

    scope.$on('$destroy', () => {
      deleteRefreshEvent();
      languageChangedEvent();
    });

    activate();

    ////

    function activate() {
      return settingsService.getSettings().then(settings => {
        appendThemeLink(settings.skin, scope);
        const customCss = (settings.features && settings.features.customCss) ? settings.features.customCss : '';
        appendCustomCssStyle(customCss);
      });
    }
  }

  function appendThemeLink(skin, scope) {
    return getThemeFile(skin)
      .then(theme => {
        let themeFile = `styles/marketplace-${skin.toLowerCase()}.css`;
        if(theme) themeFile = `styles/${theme}`;

        return $http
          .get(themeFile)
          .then(() => _appendThemeLink(themeFile, scope));
      })
      .catch(e => {
        if(skin.toLowerCase() === defaultSkin) throw e;
        return appendThemeLink(defaultSkin, scope);
      });
  }

  function appendCustomCssStyle(customCss) {
    let style;
    const customCssElements = document.getElementsByClassName('custom-css');

    if (customCssElements.length > 0) {
      style = customCssElements[0];
    } else {
      style = document.createElement('style');
      document.getElementsByTagName('head')[0].appendChild(style);
    }

    style.textContent = customCss;
    style.setAttribute('class', 'custom-css');
  }

  function _appendThemeLink(themeFile, scope) {
    let link = document.createElement('link');
    link.setAttribute('class', 'custom-theme-style');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');
    link.onload = () => scope.$emit('theme-loaded', themeFile);
    link.setAttribute('href', themeFile);
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  function getThemeFile(skin) {
    const getRealFileName = (fileRev, skin) => fileRev[`marketplace-${skin.toLowerCase()}.css`];
    if(angular.isUndefined(fileRev)) {
      return $http
        .get('/styles/manifest.json?t=' + Date.now())
        .then(response => fileRev = response.data)
        .then(fileRev => getRealFileName(fileRev, skin))
        .catch(() => null);
    } else {
      return Promise.resolve(getRealFileName(fileRev, skin));
    }
  }
}

