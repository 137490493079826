import CDPlaceOrderController from './cd-place-order';
import MarketPlaceAbstractCtrl from './marketplace-abstract';
import MarketPlaceCompanyCtrl from './marketplace-company';
import MarketplaceErrorCtrl from './marketplace-error';
import MarketPlaceOrderCtrl from './marketplace-order';
import MarketPlaceProductCtrl from './marketplace-product';
import MarketPlaceTagCtrl from './marketplace-tag';
import MarketPlaceCtrl from './marketplace';
import CdLoginDialogCtrl from './cd-login-dialog-controller';

const controllerModule = angular.module('cloudesireApp.controller', []);

controllerModule.controller('CDPlaceOrderController', CDPlaceOrderController);
controllerModule.controller('cdLoginDialogCtrl', CdLoginDialogCtrl);
controllerModule.controller('MarketPlaceAbstractCtrl', MarketPlaceAbstractCtrl);
controllerModule.controller('MarketPlaceCompanyCtrl', MarketPlaceCompanyCtrl);
controllerModule.controller('MarketplaceErrorCtrl', MarketplaceErrorCtrl);
controllerModule.controller('MarketPlaceOrderCtrl', MarketPlaceOrderCtrl);
controllerModule.controller('MarketPlaceProductCtrl', MarketPlaceProductCtrl);
controllerModule.controller('MarketPlaceTagCtrl', MarketPlaceTagCtrl);
controllerModule.controller('MarketPlaceCtrl', MarketPlaceCtrl);

export default controllerModule;
