const searchBoxStructuredData = {
  '@context': 'http://schema.org',
  '@type': 'WebSite',
  'url': '',
  'name': '',
  'potentialAction': {
    '@type': 'SearchAction',
    'target': 'https://query.example.com/search?q=',
    'query-input': 'required name=search_term_string'
  }
};

/* @ngInject */
export default function ($state, settingsService) {
  let directive = {
    restrict: 'A',
    link: cdStructuredSearchBoxDataLink,
    scope: {}
  };

  function cdStructuredSearchBoxDataLink(scope, el) {
    settingsService.getSettings().then((settings) => {
      searchBoxStructuredData.name = settings.title;
      searchBoxStructuredData.url = $state.href('marketplace.main', { q: void 0, tag: void 0 }, { absolute: true });
      searchBoxStructuredData.potentialAction.target = `${searchBoxStructuredData.url}?q={search_term_string}`;
      let rawScriptTag = document.createElement('script');
      rawScriptTag.type = 'application/ld+json';
      let scriptTag = angular.element(rawScriptTag);
      scriptTag.text(angular.toJson(searchBoxStructuredData));
      el.append(scriptTag);
    });
  }

  return directive;
}

