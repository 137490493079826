const inspectletScript = `
    window.__insp = window.__insp || [];
    __insp.push(['wid', 416747135]);
    (function() {
      function ldinsp(){if(typeof window.__inspld != "undefined") return; window.__inspld = 1; var insp = document.createElement('script'); insp.type = 'text/javascript'; insp.async = true; insp.id = "inspsync"; insp.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js'; var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(insp, x); };
      setTimeout(ldinsp, 500); document.readyState != "complete" ? (window.attachEvent ? window.attachEvent('onload', ldinsp) : window.addEventListener('load', ldinsp, false)) : ldinsp();
    })();`;

/* @ngInject */
export default function (currentUser, $log) {
  return {
    tagSession: tagSession,
    identifyUser: identifyUser,
    injectInspectlet: injectInspectlet,
    isAlreadyInjected: isAlreadyInjected
  };

  function tagSession(tag) {
    if (window.__insp) {
      window.__insp.push(['tagSession', tag]);
    }
  }

  function identifyUser() {
    if (window.__insp && currentUser.user) {
      window.__insp.push(['identify', currentUser.user.email]);
      window.__insp.push(['tagSession', { email: currentUser.user.email }]);
    }
  }

  function injectInspectlet(el) {
    let rawScriptTag = document.createElement('script');
    rawScriptTag.type = 'text/javascript';
    rawScriptTag.id = 'inspectletjs';
    let scriptTag = angular.element(rawScriptTag);
    scriptTag.text(inspectletScript);
    scriptTag.onload = identifyUser;
    el.append(scriptTag);

    $log.info('Enabling inspectlet');
  }

  function isAlreadyInjected() {
    return angular.element('#inspectletjs').length > 0;
  }
}
