'use strict';

export default function () {
  return function (input, max) {
    if (input.length > max) {
      return input.substring(0, max) + '...';
    }
    else {
      return input;
    }
  };
}
