import { tns } from 'tiny-slider/src/tiny-slider';

/* @ngInject */
export default function () {
  return {
    restrict: 'EA',
    link: cdCarouselLink,
    controller: cdCarouselController
  };

  /* @ngInject */
  function cdCarouselController($rootScope, $scope) {

    $scope.$on('loadCdCarousel', loadCdCarousel);

    function loadCdCarousel() {
      tns({
        container: `#${$scope.$id}`,
        items: 1,
        slideBy: 'page',
        autoplay: true,
        mouseDrag: true,
        controls: false,
        autoplayButtonOutput: false,
        navPosition: 'bottom'
      });

      $scope.$element.removeClass('hidden');
      $rootScope.$broadcast('cd-carousel-loaded', void 0);
    }
  }

  function cdCarouselLink(scope, element, attr) {
    scope.$element = $(element); // jshint ignore:line
    scope.$id = attr.id;
  }
}

