/* @ngInject */
export default function (
  $rootScope,
  $scope,
  $timeout,
  $mdStepper,
  $location,
  $window,
  $state,
  $translate,
  $filter,
  $log,
  i18n,
  userEditService,
  configurationService,
  deployService,
  authService,
  currentUser,
  Restangular,
  application,
  environment,
  featureService,
  keycloakService,
  localStorageService,
  Helpers,
  notify,
  estimate,
  UrlEntityService,
  settingsService
) {
  const vm = this;
  let retryCounter = 0;

  application.externalReference = `${configurationService.getCmwEnvironment()}-${Date.now()}`;

  vm.forms = {};

  const loginInformationLabel = $translate.instant('cd-place-order.registration-information');
  const billingInformationLabel = $translate.instant('cd-place-order.billing-information');
  const vmSshKeyLabel = $translate.instant('cd-place-order.vm-ssh-key');

  vm.stepLabels = {
    'loginInformation': loginInformationLabel,
    'billingInformation': billingInformationLabel,
    'vmSshKey': vmSshKeyLabel,
    'paymentMethodChoice': $translate.instant('cd-place-order.payment-method-choice'),
    'paymentInformation': $translate.instant('cd-place-order.payment-information')
  };

  vm.stepChecks = {};
  vm.stepChecks[loginInformationLabel] = 'loginInformationCheck';
  vm.stepChecks[billingInformationLabel] = 'billingInformationCheck';
  vm.stepChecks[vmSshKeyLabel] = 'vmSshKeyCheck';

  vm.loadStepper = () => {
    vm.stepper = $mdStepper('place-order-stepper');

    vm.stepper.canGoBack = (target) => {
      const currentPosition = vm.stepper.currentStep;
      const targetPosition = _.findIndex(vm.stepper.steps, step => step.label === vm.stepLabels[target]);
      return currentPosition > 0 && currentPosition -1 === targetPosition;
    };
    vm.stepper.isLastStep = (currentStep) => {
      return currentStep === (vm.stepper.steps.length - 1);
    };
    vm.stepper.getCurrentCheck = (currentStep) => {
      const currentStepLabel = vm.stepper.steps[currentStep].label;
      return vm.stepChecks[currentStepLabel] || 'noCheck';
    };
  };
  vm.isParentMarketplace = configurationService.isParentMarketplace();
  vm.application = application;

  let unwatchIsACompany, unwatchPaymentGateway, unListenPaymentValid, unListenPaymentNotValid, unwatchLegalSentencesValues;
  let loggedUser = currentUser.isLogged ? currentUser.user : void 0;

  application.cloudProvider =
    application.cloudProvider ||
    _.first(application.product.cloudProviders);

  if (!application.cloudProvider)
    Restangular
      .all('cloudProvider')
      .getList()
      .then(cps => application.cloudProvider = _.first(cps));

  vm.stripePaymentMethod = true;
  vm.paypalPaymentMethod = false;
  vm.isPaymentValid = false;
  vm.placingTheOrder = false;
  vm.currentLanguage = i18n.current();
  vm.product = application.product;
  vm.productVersion = application.productVersion;
  const isAWindowsVm = application.operatingSystem ? application.operatingSystem.includes('WINDOWS_') : false;
  vm.shouldAskSshKey = vm.product.isVm && !isAWindowsVm;
  vm.showControlPanelLink = showControlPanelLink;
  vm.controlPanelApplicationsUrl = configurationService.getControlPanelApplicationsUrl();
  vm.trialOrder = application.orderType === 'TRIAL';
  vm.saveUser = saveUser;
  vm.card = null;
  vm.keycloakFeature = false;
  vm.startOrderFlow = (card, iban) => startOrderFlow(card, iban, application);
  vm.cancel = $scope.closeThisDialog;
  vm.totalPrice = angular.copy($filter('number')(estimate.totalPrice, 2));
  vm.isFree = estimate.totalPrice === 0;
  vm.vat = estimate.vat;
  vm.manualPaymentGateway = 'MANUAL';
  vm.stripePaymentGateway = 'STRIPE';
  vm.stripeSepaPaymentGateway = 'STRIPE_SEPA';
  vm.askForBillingInformation = true;
  vm.showStepper = false;
  vm.ctaLabel = vm.productVersion.metadata ? vm.productVersion.metadata.ctalabel : null;
  vm.openLoginDialog = openLoginDialog;

  configurationService.getCmwEnvironmentObject()
    .then(environment => vm.currency = environment.configuration.currency.toUpperCase());

  $window.ssoPopup = undefined; // NOSONAR

  let ssoPopupWatch = null;
  generateKeycloakLoginUrl(i18n.current());
  function generateKeycloakLoginUrl(language) {
    featureService
      .isEnabled('keycloak')
      .then(() => {
        vm.keycloakFeature = true;
        vm.ssoLoginUrl = keycloakService.createLoginUrl(language, $state.href('sso', {}, { absolute: true }));
        keycloakService.addListener(onKeycloakSuccess, onKeycloakError);

        // check if popup has been blocked
        if (ssoPopupWatch) ssoPopupWatch();
        ssoPopupWatch = $scope.$watch(() => $window.ssoPopup, popup => {
          if (angular.isUndefined(popup)) return; // default value
          if (popup === null) { // null means popup blocked
            localStorageService.set('application', vm.application);
            $location.search('orderFlow', vm.application.orderType);
            keycloakService.login(language, $location.absUrl());
          }
        });

        $scope.$on('$destroy', () => {
          if(ssoPopupWatch) ssoPopupWatch();
        });
      });
  }

  function onKeycloakSuccess(subject, token) {
    return authService
      .tokenLogin(subject, token, Helpers.AUTH_PROVIDERS.keycloak)
      .then(user => userEditService.retrieve(user.id))
      .then(user => vm.user = vm.loggedUser = user)
      .then(() => saveUser(false));
  }

  function onKeycloakError() {
    notify.error($translate.instant('keycloak.authentication-error'));
  }

  retrieveManualPaymentExplaination()
    .then(message => vm.manualPaymentExplaination = message);

  activate().then(() => {
    if(vm.user.company && vm.user.company.delayedPayment) {
      vm.delayedPayment = true;
      $log.info('Delayed payment active');
    }

    vm.storeName = environment.storeName;

    vm.enabledPaymentGateways = vm.productVersion.paymentGateways || environment.features.enabledPaymentGateways;

    const hasStripe = vm.enabledPaymentGateways.includes(vm.stripePaymentGateway);

    vm.paymentGateway = hasStripe ? vm.stripePaymentGateway : _.first(vm.enabledPaymentGateways);
    vm.manualPaymentEnabled = vm.enabledPaymentGateways.includes(vm.manualPaymentGateway);
    vm.onlyManualPaymentEnabled = vm.manualPaymentEnabled && vm.enabledPaymentGateways.length === 1;

    vm.askForPaymentMethod = !(vm.loggedUser && vm.loggedUser.paymentDataSaved) &&
      !vm.application.selfBilled &&
      vm.productVersion.trial !== 'ALLOWED_WO_PAYMENT_METHOD' &&
      !vm.onlyManualPaymentEnabled &&
      !vm.delayedPayment;

    vm.askForBillingInformation = !vm.delayedPayment;

    vm.isACompany = !!vm.user.company;

    vm.showStepper = true;

    settingsService
      .getSettings()
      .then(settings => {
        vm.legalSentences = settings.legalSentences;
        vm.tosPrivacySentence = settings.tosPrivacySentence;

        if(vm.legalSentences && vm.legalSentences.length > 0) {
          vm.legalSentencesValues = _.map(vm.legalSentences, () => vm.user && vm.user.acceptedTerms);

          unwatchLegalSentencesValues = $scope
            .$watch(
              () => vm.legalSentencesValues,
              () => vm.user.acceptedTerms = _.every(vm.legalSentencesValues || [false]),
              true
            );
        }
      });

    unwatchIsACompany = $scope.$watch(() => vm.isACompany, isACompany => {
      if (isACompany === true) {
        vm.user.company = vm.user.company || {};
        if (vm.user.address) {
          vm.user.company.address = Restangular.copy(vm.user.address);
          delete vm.user.company.address.id;
          delete vm.user.address;
        }
      }
      else if (isACompany === false && vm.user.company) {
        vm.user.address = Restangular.copy(vm.user.company.address);
        delete vm.user.address.id;
        delete vm.user.company;
        delete vm.user.userCompany;
      }
    });

    unwatchPaymentGateway = $scope.$watch(() => vm.paymentGateway, cleanPaymentFields);

    unListenPaymentValid = $scope.$on('payment-valid', () => {
      vm.isPaymentValid = true;
      $scope.$apply();
    });

    unListenPaymentNotValid = $scope.$on('payment-not-valid', () => {
      vm.isPaymentValid = false;
      $scope.$apply();
    });
  });

  const unlistenLanguageChanged = $scope.$on('language-changed', (ev, language) => generateKeycloakLoginUrl(language));

  /////////////////////////////////////

  function activate() {
    return (loggedUser ?
      userEditService.retrieve(loggedUser.id).then(user => vm.loggedUser = user) :
      Promise.resolve({ address: {} })).then(user => vm.user = user);
  }

  function saveUser(goAhead) {
    const role = 'ROLE_USER';
    vm.actionInProgress = true;

    let userCompany = Promise.resolve();
    if (vm.user.company) {
      vm.user.company.emailAddress = vm.user.company.emailAddress || vm.user.email;
      const userCompanyService = Restangular.restangularizeElement(null, vm.user.company, 'userCompany');
      const userCompanyPromise = vm.user.company.id ? userCompanyService.put() : userCompanyService.post();
      userCompany = userCompanyPromise.then(company => {
        vm.user.company = company;
        return Promise.resolve(company);
      });
    }

    return userCompany
      .return(vm.user)
      .then(user =>
        user.id ? // already registered?
          user.put() : // then update the profile
          userEditService // else create a new user
            .create(user, role)
            .then(_user => {
              loggedUser = _user;
              _user.password = user.password; // remember the password because I need it to login
              return _user;
            })
            .then(user => authService.login(user, true)) // log in the user after the registration
            .tap(() => vm.loggedUser = loggedUser)
            .tap(() => vm.user = Restangular.copy(loggedUser))
      )
      .tap(() => goAhead ? vm.stepper.next() : angular.noop())
      .finally(() => vm.actionInProgress = false);
  }

  function startOrderFlow(card, iban, application) {
    vm.actionInProgress = true;
    showLoadingScreen(true);

    return vm.saveUser()
      .then(() => shouldAskForPaymentMethod(card, iban))
      .then(() => deployService.deployObject(application))
      .then(order => UrlEntityService.retrieve(order.subscription))
      .then(subscription => UrlEntityService.retrieveList(subscription.invoices))
      .then(invoices => {
        const invoice = invoices[0]; // New orders have only one invoice

        if (invoice) {
          return Promise.resolve({
            id: invoice.id,
            lastPaymentError: invoice.lastPaymentError
          });
        }

        return Promise.resolve({});
      })
      .then(vm.showControlPanelLink)
      .catch(error => {
        const { status } = error;
        // check order reference duplicated
        if (status === 422 && error.data.errorHolders[0].key === 'order_external_reference_key') {
          return vm.showControlPanelLink();
        }

        if (status === 504) { // gateway timeout
          retryCounter++;
          if (retryCounter <= 12) { // 12 x every 5s = 1 minute
            $log.info('got 504 while placing order, retry ' + retryCounter);
            return setTimeout(() => startOrderFlow(card, iban, application), 5000);
          } else {
            retryCounter = 0;
            notify.error($translate.instant('cd-place-order.timeout-check-dashboard'));
          }
        }

        return showLoadingScreen(false);
      })
      .finally(() => {
        vm.actionInProgress = false;
        activate();
      });
  }

  function shouldAskForPaymentMethod(card, iban) {
    if(!vm.askForPaymentMethod) return Promise.resolve();
    if (vm.paymentGateway === vm.stripeSepaPaymentGateway) return vm.loggedUser.attachSepa(iban);
    if (vm.paymentGateway === vm.stripePaymentGateway) return vm.loggedUser.attachCreditCard(card);
    if (vm.paymentGateway === vm.manualPaymentGateway) return Promise.resolve(vm.manualPaymentGateway);
    throw new Error(`Missing payment gateway ${vm.paymentGateway}`);
  }

  let mdDialogHeight;
  function showLoadingScreen(show) {
    $timeout(() => {
      const mdDialogContent = angular.element('.cd-place-order-dialog .md-dialog-content');
      const mdStepper = angular.element('.cd-place-order-dialog md-stepper');
      const cdSpinner = angular.element('.cd-place-order-dialog #cd-spinner-order');
      const mdDialogActions = angular.element('.cd-place-order-dialog md-dialog-actions');

      mdDialogHeight = mdDialogHeight ? mdDialogHeight : mdDialogContent.outerHeight();

      if (show) {
        mdDialogContent.animate({ height: 150 }, 500);
        mdStepper.fadeOut();
        mdDialogActions.fadeOut();
        cdSpinner.fadeIn();
      } else {
        mdDialogContent.animate({ height: mdDialogHeight }, 500);
        cdSpinner.fadeOut();
        mdDialogActions.fadeIn();
        mdStepper.fadeIn();
      }
    });
  }

  function showControlPanelLink(invoiceData) {
    if (invoiceData && invoiceData.lastPaymentError) {
      vm.paymentRequiresAction = invoiceData.lastPaymentError === 'requires_action';
      vm.controlPanelApplicationsUrl = `${vm.controlPanelApplicationsUrl}?sca=${invoiceData.id}`;
    } else {
      vm.paymentRequiresAction = false;
    }

    $timeout(() => {
      const ngDialogContent = angular.element('.cd-place-order-dialog .ngdialog-content');
      const placeOrderForm = angular.element('div#place-order-form');

      ngDialogContent.height(placeOrderForm.height());
      ngDialogContent
        .animate({ height: 200 }, 500);
      placeOrderForm.animate({
        opacity: 0
      }, 500, function () {
        angular
          .element('.thank-you-for-your-order')
          .fadeIn(100);
        placeOrderForm.hide();
      });
    });
  }

  function cleanPaymentFields() {
    vm.card = vm.iban = vm.sepaTerms = void 0;
    vm.isPaymentValid = false;
  }

  function retrieveManualPaymentExplaination() {
    return settingsService
      .getSettings()
      .then(settings => {
        let manualPaymentExplaination = settings.manualPaymentExplaination;

        if (!manualPaymentExplaination) {
          return $translate('cd-place-order.payment-method.manual-explain');
        }

        return Promise.resolve(manualPaymentExplaination);
      });
  }

  function openLoginDialog() {
    vm.cancel();

    $timeout(() => {
      $rootScope.$broadcast('open-login-dialog', void 0);
    }, 500);
  }

  $scope.$on('$destroy', () => {
    if (unwatchIsACompany) unwatchIsACompany();
    if (unwatchPaymentGateway) unwatchPaymentGateway();
    if (unListenPaymentValid) unListenPaymentValid();
    if (unListenPaymentNotValid) unListenPaymentNotValid();
    if (unwatchLegalSentencesValues) unwatchLegalSentencesValues();
    if (unlistenLanguageChanged) unlistenLanguageChanged();
  });
}
