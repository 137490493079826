import './router.module';
import 'restangular';

const resourceModule = angular
  .module('cloudesireApp.resource', [
    'cloudesireApp.router',
    'restangular'
  ]);

function handleRejection(rejection, $q) {
  if (0 >= rejection.status) return $q.reject(rejection);

  try {
    if(rejection.config && _.some(rejection.config.params, (_, key) => key === 'skipError')) {
      return $q.reject(rejection);
    }
  } catch(_) {} // continue if rejection data doesn't exist

  if (401 === rejection.status) return $q.reject(rejection);

  try {
    if (422 === rejection.status && rejection.data.errorHolders[0].key === 'order_external_reference_key') {
      return $q.reject(rejection);
    }
  } catch(_) {} // continue if rejection data doesn't exist

  try {
    if (504 === rejection.status && rejection.config.url.indexOf('/order') !== -1) {
      return $q.reject(rejection);
    }
  } catch (_) { } // continue if not an order timeout error
}

resourceModule
  .config(['RestangularProvider', 'configurationServiceProvider', '$httpProvider', '$provide',
  function(RestangularProvider, configurationServiceProvider, $httpProvider, $provide) {
    RestangularProvider.setDefaultHeaders({'Mode': configurationServiceProvider.getCmwEnvironment()});
    RestangularProvider.setBaseUrl(configurationServiceProvider.getBaseUrl());

    window.ajaxRequests = 0;

    // Intercept $http calls.
    $provide.factory('CloudesireHttpInterceptor', ['$q', 'notify', '$sanitize', function($q, notify, $sanitize) {
      return {
        // On request success
        request: function (config) {
          window.ajaxRequests++;
          return config || $q.when(config);
        },

        // On request failure
        requestError: function (rejection) {
          // Return the promise rejection.
          return $q.reject(rejection);
        },

        // On response success
        response: function (response) {
          window.ajaxRequests--;
          // Return the response or promise.
          return response || $q.when(response);
        },

        // On response failure
        responseError: function(rejection) {
          window.ajaxRequests--;

          const rejectionPromise = handleRejection(rejection, $q);
          if (rejectionPromise) return rejectionPromise;

          let translatedErrors = [], untranslatedErrors = [];
          let errors = rejection.data.errorHolders || rejection.data.errors;
          if(!angular.isArray(errors)) errors = [errors];

          Promise.map(errors, error => {
            if (!error) return Promise.resolve();
            return notify
              .getErrorTranslation(error)
              .then(error => translatedErrors.push(error))
              .catch(error => untranslatedErrors.push(error));
          }).then(() => {
            let translatedErrorsMessages = $sanitize(translatedErrors.join('<br/>'));
            let untranslatedErrorsMessage = $sanitize(untranslatedErrors.join('<br/>'));

            if(translatedErrors.length > 0) sendNotify(rejection.status, translatedErrorsMessages);
            if(untranslatedErrors.length > 0) {
              let errorMessageKey = rejection.status >= 500 ? 'errors.default-server-error-message' : 'errors.default-client-error-message';

              let randomId = Math.random().toString(36).substring(7);

              let errorMessage = `
                <span>{{'${errorMessageKey}'|translate}}</span>
                <div class="show-more-error">
                  <input id="${randomId}" type="checkbox" name="tabs">
                  <label for="${randomId}">
                    <span class="accordion-header">
                      <fa style="padding-right: 2px;" name="info-circle"/>
                      <span>{{'notification.more-details'|translate}}</span>
                    </span>
                  </label>
                  <div class="tab-content">
                    <p>${untranslatedErrorsMessage}</p>
                  </div>
                </div>`;
              sendNotify(rejection.status, errorMessage);
            }
          });

          return $q.reject(rejection);
        }
      };

      function sendNotify(httpStatus, errorMessage) {
        if(httpStatus >= 500) return notify.error(errorMessage);
        return notify.warning(errorMessage);
      }
    }]);

    // Add the interceptor to the $httpProvider.
    $httpProvider.interceptors.push('CloudesireHttpInterceptor');
    $httpProvider.interceptors.push('browserCacheInterceptor');

    $httpProvider.defaults.transformRequest.unshift(function(data) {
      if(data && data.sluggedName) delete data.sluggedName;
      return data;
    });

  }
])
  .run(runFn);

export default resourceModule;

/* @ngInject */
function runFn(
  Restangular,
  notify,
  currentUser,
  authService,
  $rootScope,
  $window,
  $translate,
  $location,
  entityToUrlService,
  Slug,
  configurationService,
  localStorageService
) {
  const globalCacheInvalidatorKey = 'globalCacheInvalidator';
  localStorageService.set(globalCacheInvalidatorKey, localStorageService.get(globalCacheInvalidatorKey) || 0);

  Restangular
    .addFullRequestInterceptor((element, operation, what, url, header, queryParameters) => {
      const qp = angular.copy(queryParameters);
      const isChildMarketplace = configurationService.isChildMarketplace();

      if(isChildMarketplace) {
        const ownerUsername = configurationService.getOwnerUsername();

        qp.reseller = configurationService.isResellerChildMarketplace() ? ownerUsername : void 0;
        qp.distributor = configurationService.isDistributorChildMarketplace() ? ownerUsername : void 0;
      }

      if(currentUser.user && currentUser.user.id) qp.u = currentUser.user.id;

      qp.apiVersion = configurationService.getApiVersion();

      qp.gci = localStorageService.get(globalCacheInvalidatorKey);

      return {
        params: qp
      };
    })
    .addRequestInterceptor(function(element, operation) {
      // delete originalElement, the original response injected by Restangular
      if(element && _.has(element, 'originalElement')) delete element.originalElement;
      // transform entity to key: entity/id
      if('post' === operation || 'put' === operation) {
        element = entityToUrlService.convert(element);
      }

      // not a POST or PUT
      return element;
    }).addResponseInterceptor(function(data, operation, what, url, response, deferred) {
      if(angular.isArray(data)) {
        let el = [];
        angular.forEach(data, d => el.push(_.extend(d, {})));

        if (operation === 'getList') {
          el.pagination = {};
          el.pagination.totalPages = response.headers('CMW-TotalPages');
          el.pagination.pageSize = response.headers('CMW-PageSize');
          el.pagination.totalItems = response.headers('CMW-TotalItems');
          el.pagination.pageNumber = response.headers('CMW-PageNumber');
        }

        return Restangular
          .restangularizeCollection(data[Restangular.configuration.restangularFields.parentResource], el, what);
      }else if(angular.isObject(data)) {
        let el = _.extend(deferred.promise.$object, data);
        return Restangular
        .restangularizeElement(data[Restangular.configuration.restangularFields.parentResource], el, what);
      }else return data;
    }).setResponseExtractor(function(response) {
      if('object' === typeof response) {
        response.originalElement = angular.copy(response);
      }
      return response;
    }).setErrorInterceptor(function(response) {
      let baseUrl = configurationService.config.endpoint.url;
      let hasASavedToken = !!localStorageService.get('token') || currentUser.isLogged;
      if(401 === response.status && hasASavedToken && response.config.url.indexOf(baseUrl) >= 0) {
        $rootScope.referrer = $location.path();
        notify.pushMessage($translate.instant('marketplace.session-expired'), 'info');
        authService.logout();
      }
    }).setOnElemRestangularized(function(elem, isCollection) {
      if(isCollection) {
        elem.first = function() {
          return elem[0];
        };
      }else if(elem.name !== undefined) {
        elem.sluggedName = Slug.slugify(elem.name);
      }
      return elem;
    });

    $rootScope.$on('marketplace-configuration-changed', (ev, configuration) => {
       $window.config.default = configuration;
       Restangular.setDefaultHeaders({'Mode': configurationService.getCmwEnvironment()});
    });
}
