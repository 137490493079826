export default function () {
  return {
    restrict: 'E',
    controller: cdGlobalProgressController,
    template: `<md-progress-linear
        id="cd-global-progress"
        style="position: fixed; top: 0; width: 100%; z-index: 999"
        md-mode="determinate"
        ng-hide="hideBar === true"
        value="{{resourcesCounter}}">
      </md-progress-linear>`
  };
}

/* @ngInject */
function cdGlobalProgressController($scope, $timeout, $window) {
  $scope.$watch(() => $window.ajaxRequests, (ajaxRequestsNew, ajaxRequestsOld) => {
    ajaxRequestsNew = ajaxRequestsNew || 0;
    ajaxRequestsOld = ajaxRequestsOld || 0;

    if (ajaxRequestsOld === 0) return;
    $scope.resourcesCounter = 100 - (ajaxRequestsNew / ajaxRequestsOld * 100);
    if ($scope.resourcesCounter === 100 && ajaxRequestsNew === 0 || $scope.resourcesCounter === 0)
      $timeout(() => $scope.hideBar = true, 200);
    else $scope.hideBar = false;
  });
}

