import commonState from './common-state';
import api from './api';
import applicationRating from './application-rating';
import auth from './auth';
import bandwidthPricing from './bandwidth-pricing';
import budgetEstimate from './budget-estimate';
import cloudProvider from './cloud-provider';
import cloudCredential from './cloud-credential';
import companyService from './company-service';
import configurationService from './configuration-service';
import coupon from './coupon';
import fileEdit from './file-edit';
import inspectlet from  './inspectlet';
import prerenderService from  './prerender-service';
import productEdit from  './product-edit';
import productVersionEdit from  './product-version-edit';
import urlEntity from  './url-entity';
import userEdit from  './user-edit';

const factoryModule = angular.module('cloudesireApp.factory', []);

factoryModule.factory('commonState', commonState);
factoryModule.factory('apiFactory', api);
factoryModule.factory('applicationRatingService', applicationRating);
factoryModule.factory('authFactory', auth);
factoryModule.factory('BandwidthPricing', bandwidthPricing);
factoryModule.factory('BudgetEstimate', budgetEstimate);
factoryModule.factory('CloudProvider', cloudProvider);
factoryModule.factory('CloudCredential', cloudCredential);
factoryModule.factory('Company', companyService);
factoryModule.factory('Configuration', configurationService);
factoryModule.factory('CouponService', coupon);
factoryModule.factory('FileService', fileEdit);
factoryModule.factory('inspectletService', inspectlet);
factoryModule.factory('prerenderService', prerenderService);
factoryModule.factory('productEditService', productEdit);
factoryModule.factory('productVersionEditService', productVersionEdit);
factoryModule.factory('UrlEntityService', urlEntity);
factoryModule.factory('userEditService', userEdit);

export default factoryModule;
