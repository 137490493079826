/* @ngInject */
export default function (
  ngDialog,
  $scope
) {
  const vm = this;

  const unlistenOpenLoginDialog = $scope.$on('open-login-dialog', (event) => {
    event.preventDefault();
    vm.startLogin();
  });

  vm.startLogin = () => {
    ngDialog.open({
      controller: 'cdLoginDialogCtrl',
      controllerAs: 'vm',
      className: 'cd-dialog',
      appendClassName: 'cd-login-dialog',
      template: 'views/directive/cd-login.html',
      appendTo: '.dialog-parent',
      showClose: false,
      closeByDocument: false,
      closeByEscape: true,
      closeByNavigation: true
    });
  };

  $scope.$on('$destroy', () => {
    unlistenOpenLoginDialog();
  });
}
