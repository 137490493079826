const scope = {
  estimateLines: '=budgetEstimateLines',
  product: '=product',
  productVersion: '=productVersion'
};

export default function () {
  return {
    restrict: 'E',
    controller: cdEstimateDetailCtrl,
    controllerAs: 'vm',
    transclude: true,
    templateUrl: 'views/directive/cd-estimate-detail.html',
    scope: scope
  };
}

/* @ngInject */
function cdEstimateDetailCtrl($scope) {
  const vm = this;
  let unwatchEstimateLines = $scope.$watch('estimateLines', updateEstimateLines, true);

  //

  function updateEstimateLines(estimateLines) {
    vm.lines = [];
    const isBundle = $scope.product.isBundle;
    const importantConfigurationLine = isBundle || (estimateLines.length === 1 && estimateLines[0].type === 'CONFIGURATION');

    let allBillingItemValues = [$scope.productVersion.billingItemValues];
    _.each($scope.productVersion.bundleVersions, version => {
      allBillingItemValues.push(version.billingItemValues);
    });

    let flattenBillingItemValues = _.flattenDeep(allBillingItemValues);

    let couponDiscountLines;
    if (!isBundle) {
      [estimateLines, couponDiscountLines] = _.partition(estimateLines, line => line.type !== 'COUPONDISCOUNT');
    }

    generatePrintableEstimateLines(estimateLines, importantConfigurationLine, flattenBillingItemValues);

    if (!_.isEmpty(couponDiscountLines)) {
      const newLine = {};
      newLine.priceExcludingVAT = _.reduce(couponDiscountLines, (previous, line) => previous + line.priceExcludingVAT, 0);
      newLine.discount = couponDiscountLines[0].discount;
      newLine.description = 'marketplace.product-estimate.discount';
      vm.lines.push(newLine);
    }
  }

  function findBillingItemValue(flattenBillingItemValues, line) {
    return _.find(flattenBillingItemValues, el => {
      const billingItemValueFound = el.item.self === line.billingItem.url;
      return billingItemValueFound && el.tag ? line.maximum >= el.start && line.maximum <= el.end : billingItemValueFound;
    });
  }

  function generatePrintableEstimateLines(estimateLines, importantConfigurationLine, flattenBillingItemValues) {
    _.each(estimateLines, line => {
      let newLine = {};
      // default
      newLine.priceExcludingVAT = line.priceExcludingVAT;
      newLine.discount = void 0;

      switch (line.type) {
        case 'CONFIGURATION':
          newLine.description = line.description;
          if (importantConfigurationLine || newLine.priceExcludingVAT > 0) vm.lines.push(newLine);
          break;
        case 'VIRTUALMACHINE':
          newLine.description = 'marketplace.product-estimate.cloud-provider';
          vm.lines.push(newLine);
          break;
        case 'DISKSPACE':
          newLine.description = 'marketplace.product-estimate.disk-space';
          vm.lines.push(newLine);
          break;
        case 'BANDWIDTH':
          newLine.description = 'marketplace.product-estimate.bandwidth';
          newLine.unit = line.unit;
          newLine.maximum = line.quantity;
          newLine.type = line.type;
          vm.lines.push(newLine);
          break;
        case 'COUPONDISCOUNT': // bundles only
          newLine.discount = line.discount;
          newLine.description = 'marketplace.product-estimate.bundle-discount';
          vm.lines.push(newLine);
          break;
        case 'BILLINGITEMCOST':
          // search for the billing item to find its name
          let billingItemValue = findBillingItemValue(flattenBillingItemValues, line);
          if (!billingItemValue) break;

          // find if line already exists to only update the price
          let billingItemLine = _.find(vm.lines, { description: billingItemValue.item.name });

          if (billingItemLine) {
            billingItemLine.priceExcludingVAT = line.priceExcludingVAT + billingItemLine.priceExcludingVAT;
            billingItemLine.maximum = line.maximum > billingItemLine.maximum ? line.maximum : billingItemLine.maximum;
            billingItemLine.tag = billingItemValue.tag;
          } else {
            newLine.description = billingItemValue.item.name;
            newLine.tag = billingItemValue.tag;
            newLine.unit = line.unit;
            newLine.maximum = line.maximum;
            newLine.type = line.type;
            vm.lines.push(newLine);
          }
          break;
      }
    });
  }

  $scope.$on('$destroy', unwatchEstimateLines);
}

