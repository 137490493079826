/* @ngInject */
export default function (Restangular, UrlEntityService, $translate) {
  return angular.extend(Restangular.service('cloudCredential'), {
    getAll: getAll
  });

  function getAll(cloudProvider) {
    return this.getList({providerId: cloudProvider.id})
      .then(cloudCredentials => {
        return cloudCredentials.map(cloudCredential => {
          if (cloudCredential.subscription) {
            cloudCredential.subscriptionDescription = '(' + $translate.instant('marketplace.order-setup.subscription') + ' #' + UrlEntityService.toId(cloudCredential.subscription) + ')';
          }

          return cloudCredential;
        });
      });
  }
}
