export default function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: (scope, el, attrs, ngModel) => {
      ngModel.$parsers.push(text => {
        const upperText = angular.uppercase(text);
        ngModel.$setViewValue(upperText);
        ngModel.$render();
        return upperText;
      });
    }
  };
}

