/* @ngInject */
export default function ($http, configurationService, currentUser) {
  var url = configurationService.config.endpoint.url;
  return {
    login: function (credentials) {
      var headers;

      if (credentials) {
        headers = {
          'Authorization': 'Basic ' + window.btoa(credentials.username + ':' + credentials.password)
        };
      }

      return $http({
        method: 'GET',
        url: url + '/login?t='+Date.now(),
        headers: headers,
        cache: false
      });
    },
    isLogged: function () {
      return null !== currentUser.user;
    },
    logout: () => {
      return $http({
        method: 'POST',
        url: url + '/logout',
        cache: false
      });
    }
  };
}
