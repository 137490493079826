import noUiSlider from 'nouislider';

/* @ngInject */
export default function ($timeout) {
  return {
    restrict: 'E',
    require: 'ngModel',
    replace: true,
    templateUrl: 'views/directive/cd-billing-item-slider.html',
    scope: {
      ngModel: '=',
      ngDisabled: '=',
      ngChange: '&',
      billingItemGroup: '='
    },
    link: (scope, el) => {
      const ngModelInputThirdPartyUpdate = _.debounce(_new => {
        scope.ngModelInput = parseInt(_new);
        updateSlider(scope.ngModelInput);
      }, 500);

      const unwatchModel = scope.$watch('ngModel', ngModelInputThirdPartyUpdate);

      const slider = el.find('.non-linear-slider')[0];
      const ranges = generateRanges();

      const disabledWatch = scope.$watch('ngDisabled', (disabled) => {
        if (disabled) {
          slider.setAttribute('disabled', true);
          slider.classList.add('disabled');
        }
        else {
          slider.removeAttribute('disabled');
          slider.classList.remove('disabled');
        }
      });

      noUiSlider.create(slider, {
        connect: true,
        behaviour: 'tap',
        start: [0],
        range: ranges
      });

      function updateSlider(value) {
        slider.noUiSlider.set(value);
      }

      scope.updateSlider = _.debounce(updateSlider, 500);
      scope.updateStep = updateStep;

      function generateRanges() {
        const firstBillingItemValue = scope.billingItemGroup[0];
        const lastBillingItemValue = scope.billingItemGroup[scope.billingItemGroup.length - 1];
        const ranges = {
          min: [firstBillingItemValue.start, firstBillingItemValue.step]
        };

        let lastGroup = null;
        _.forEach(scope.billingItemGroup, (group, index) => {
          lastGroup = group;
          if (index === 0) return;
          const percentage = calculatePercentage(lastBillingItemValue.end, group.start);
          ranges[percentage] = [group.start, group.step];
        });

        ranges.max = [lastBillingItemValue.end, lastGroup.step];
        return ranges;
      }

      function calculatePercentage(max, partial) {
        return parseInt(partial * 100 / max) + '%';
      }

      slider.noUiSlider.on('update', function (values, handle) {
        scope.ngModel = parseInt(values[handle]);
        scope.ngModelInput = angular.copy(scope.ngModel);
        scope.ngChange();

        updateStep();

        $timeout(() => {
          scope.$apply();
        });
      });

      updateStep();

      function updateStep() {
        if(!angular.isNumber(scope.ngModelInput)) return;
        const inputElement = el.find('.slider-quantity')[0];
        inputElement.step = _.findLast(ranges, range => scope.ngModelInput >= range[0])[1];
      }

      scope.$on('$destroy', () => {
        disabledWatch();
        unwatchModel();
      });
    }
  };
}

