/* @ngInject */
export default function (
  $scope,
  $state,
  $window,
  $stateParams,
  $log,
  $timeout,
  $location,
  $translate,
  i18n,
  CloudCredential,
  productEditService,
  BandwidthPricing,
  BudgetEstimate,
  currentUser,
  UrlEntityService,
  productVersionEditService,
  configurationService,
  settingsService,
  productVersions,
  prerenderService,
  localStorageService,
  featureService,
  notify
) {
  const vm = this;
  let unwatchers = [], unwatchLanguage, unwatchUseCustomCredentials, recommendedVersion;

  const status = Object.freeze({
    PENDING: 'PENDING',
    COMPLETED: 'COMPLETED'
  });

  vm.cloudCredential = {
    status: status.PENDING,
  };

  vm.showDialog = showDialog;
  vm.company = currentUser.user ? currentUser.user.company : null;
  vm.isAdministrator = currentUser.isAdministrator();
  vm.isEmpty = _.isEmpty;
  vm.isNil = _.isNil;
  vm.currentLanguage = i18n.current();
  vm.calculateBudgetEstimate = calculateBudgetEstimate;
  vm.productVersions = productVersions;
  vm.parseInt = parseInt;
  vm.showAskForInformation = void 0;
  vm.nullPriceVersion = void 0;
  vm.useCustomCredential = void 0;
  vm.estimateMode = false;
  vm.setEstimateMode = setEstimateMode;
  vm.showAdvancedSettings = showAdvancedSettings;
  vm.onCloudProviderChanged = onCloudProviderChanged;
  vm.planHasRequiredBillingItems = planHasRequiredBillingItems;
  vm.billingItemValueIsRequired = billingItemValueIsRequired;
  vm.goToComparableVersion = () => {
    vm.order.estimateMode = vm.estimateMode;
    localStorageService.set('application', vm.order);
    $state.go('marketplace.product.order', {
      product: vm.productComparable.id,
      productSlug: vm.productComparable.sluggedName,
      categorySlug: vm.productComparable.category && vm.productComparable.category.sluggedName ? vm.productComparable.category.sluggedName : '',
      version: vm.productVersionComparable.id
    });
  };

  const orderObject = {
    bandwidthPricing: void 0,
    hashCoupon: $stateParams.coupon,
    isCustomerCloudCredentialsOnly: false
  };

  vm.previousOrder = localStorageService.get('application');
  localStorageService.remove('application');

  if (vm.previousOrder) {
    vm.estimateMode = vm.previousOrder.estimateMode;
  }

  vm.order = orderObject;
  vm.numberInputOpts = {};

  const getBudgetEstimate = _.debounce(checkSettingsAndGetBudgetEstimate, 500);

  let _destroy = () => angular.forEach(unwatchers, unwatcher => unwatcher());

  unwatchLanguage = $scope.$on('language-changed', (ev, language) => {
    vm.currentLanguage = language;

    _destroy();
    activate();

    productVersionEditService
      .retrieveAllByProduct($stateParams.product, false, true, language)
      .then(productVersions => {
        vm.productVersions = productVersions;
        if(!vm.order.productVersion) return;

        changeVersion(vm.order.productVersion.id);

        if(vm.order.productVersion.billingItemValues) {
          vm.order.productVersion.initializeBillingItems();
        }
      });

    _.each(productVersions, productVersion => {
      refreshBillingItems(productVersion.billingItemApiPayAsYouGoGroups);
      refreshBillingItems(productVersion.billingItemClassicPayAsYouGoGroups);
      refreshBillingItems(productVersion.billingItemApiPrepaidGroups);
      refreshBillingItems(productVersion.billingItemClassicPrepaidGroups);
      refreshBillingItems(productVersion.billingItemApiPostpaidGroups);
      refreshBillingItems(productVersion.billingItemClassicPostpaidGroups);
    });

    if (vm.order.productVersion) {
      retrieveConfigurationParameters(vm.order.productVersion);
    }
  });

  function changeVersion(version) {
    if (version && !isNaN(version)) {
      vm.order.productVersion = _.find(vm.productVersions, { id: parseInt(version) }) || vm.order.productVersion;
    } else {
      vm.order.productVersion = recommendedVersion;
    }
  }

  unwatchers.push(
    $scope.$on('change-version', (ev, version) => {
      ev.stopPropagation();
      changeVersion(version);
    })
  );

  unwatchers.push(
    $scope.$watch(
      () => vm.configurationParameters,
      () => {
        localStorageService.set('vm.configurationParameters', vm.configurationParameters);
        vm.order.configurationParameters = productVersionEditService
          .collectConfigurationParameters(vm.configurationParameters);
      },
      true
    )
  );

  activate();

  ////////////////

  function activate() {
    productEditService
      .retrieve($stateParams.product)
      .then(product => product.initialize())
      .catch(e => {
        $state.go('marketplace.main')
          .then(() => prerenderService.set404());
        throw e;
      })
      .then(product => vm.order.product = vm.product = product)
      .then(() =>
        vm.order.productVersion = recommendedVersion = vm.order.productVersion || (
          vm.product.recommendedVersion && vm.product.recommendedVersion.published ?
            _.find(vm.productVersions, { id: vm.product.recommendedVersion.id }) :
            _.first(vm.productVersions)
        )
      )
      .then(initializeBillingItems)
      .then(() => {
        vm.controlPanelProductUrl = configurationService
          .getControlPanelProductUrl(vm.product.id, vm.product.sluggedName);

        refreshCloudProviders();

        if (vm.product.company) {
          productEditService
            .getList({
              company: vm.product.company.id,
              excluded: vm.product.id,
              language: vm.currentLanguage,
              pageSize: 4
            })
            .then(productEditService.partiallyInitializeAll)
            .then(sameCompanyProducts => vm.sameCompanyProducts = sameCompanyProducts);
        }

        if (vm.product.category) {
          productEditService
            .getList({
              categoryId: vm.product.category.id,
              excluded: vm.product.id,
              language: vm.currentLanguage,
              pageSize: 4
            })
            .then(productEditService.partiallyInitializeAll)
            .then(sameCategoryProducts => vm.sameCategoryProducts = sameCategoryProducts);
        }

        if (vm.previousOrder) {
          copyBillingItemGroupQuantity(vm.order.productVersion.billingItemApiPayAsYouGoGroups, vm.previousOrder.productVersion.billingItemApiPayAsYouGoGroups);
          copyBillingItemGroupQuantity(vm.order.productVersion.billingItemClassicPayAsYouGoGroups, vm.previousOrder.productVersion.billingItemClassicPayAsYouGoGroups);
          copyBillingItemGroupQuantity(vm.order.productVersion.billingItemApiPrepaidGroups, vm.previousOrder.productVersion.billingItemApiPrepaidGroups);
          copyBillingItemGroupQuantity(vm.order.productVersion.billingItemClassicPrepaidGroups, vm.previousOrder.productVersion.billingItemClassicPrepaidGroups);
          copyBillingItemGroupQuantity(vm.order.productVersion.billingItemApiPostpaidGroups, vm.previousOrder.productVersion.billingItemApiPostpaidGroups);
          copyBillingItemGroupQuantity(vm.order.productVersion.billingItemClassicPostpaidGroups, vm.previousOrder.productVersion.billingItemClassicPostpaidGroups);

          vm.previousOrder = null;
        }

        unwatchers.push($scope.$watch('vm.order.cloudProvider', getBandiwdthPricingList));
        unwatchers.push($scope.$watch('vm.order.productVersion', (version, oldVersion) => {
          if ((!oldVersion || version.id === oldVersion.id) && vm.configurationParameters) return;
          retrieveConfigurationParameters(version);
          refreshCloudProviders();
          vm.order.hashCoupon = null;
          vm.estimateMode = false;
        }));

        unwatchers.push(
          $scope.$watch(
            'vm.order',
            order => {
              getBudgetEstimate(order);
              vm.pendingEstimate = !!order;
              vm.pendingEstimateWithCoupon = vm.pendingEstimate && !!order.hashCoupon;
            },
            true)
        );

        prerenderService.pageReady();
      });
  }

  function copyBillingItemGroupQuantity(targetGroups, originGroups) {
    _.each(targetGroups, group => {
      const originGroup = _.find(originGroups, oGroup => group[0].item.id === oGroup[0].item.id);

      if(originGroup) {
        group.quantity = parseInt(`${originGroup.quantity}`);
        group.include = originGroup.include;
      }
    });
  }

  function needsCloudProvider() {
    return (vm.product.isBundle &&
      _.find(vm.order.productVersion.bundleVersions, version => version.productType === 'MANAGED' || version.productType === 'CLOUD_SERVICE')) ||
      vm.product.isManaged ||
      vm.product.isCloudService;
  }

  function refreshCloudProviders() {
    if (needsCloudProvider() && !_.isNil(vm.order.productVersion.price)) {
      if (vm.previousOrder) {
        vm.cloudProvider = vm.order.cloudProvider = vm.previousOrder.cloudProvider;
      } else {
        vm.cloudProvider = vm.order.cloudProvider = _.first(vm.order.productVersion.cloudProviders) || _.first(vm.order.product.cloudProviders);
      }

      if(!vm.cloudProvider) {
        notify.error($translate.instant('marketplace-product.managed-without-cloud-provider'));
        return;
      }

      onCloudProviderChanged(vm.order.cloudProvider);
    }
  }

  function initializeBillingItems() {
    return Promise.map(vm.productVersions, pv => pv.initializeBillingItems());
  }

  function retrieveConfigurationParameters(productVersion, chosenConfigurationParameters = {}) {
    if (!productVersion) return;
    productVersionEditService.retrieveConfigurationParameters(
      vm.order.product.configurationParameters,
      productVersion,
      chosenConfigurationParameters,
      currentUser.user ? currentUser.user.id : undefined,
      vm.order.cloudCredential
    )
      .then(configurationParameters => vm.configurationParameters = configurationParameters);
  }

  function refreshBillingItems(billingItemsGroup) {
    if (_.isEmpty(billingItemsGroup)) return;

    _.each(billingItemsGroup, billingItemGroup =>
      _.each(billingItemGroup, billingItemValue =>
        UrlEntityService
          .retrieve(billingItemValue.item)
          .then(item => {
            billingItemValue.item.name = item.name;
            billingItemValue.item.description = item.description;
            billingItemValue.item.unit = item.unit;
          })
      )
    );
  }

  function getBandiwdthPricingList(cloudProvider) {
    if (cloudProvider) {
      vm.order.bandwidthPricing = null;
      BandwidthPricing.getList({ cloudProvider: cloudProvider.id }).then(function (bandwidthPricingList) {
        vm.bandwidthPricingList = bandwidthPricingList;
        vm.order.bandwidthPricing = vm.bandwidthPricingList[0];
      });
    }
  }

  function isNullPriceBundle(order) {
    if (order.product.isBundle) {
      const anyNullPrice = _.find(order.productVersion.bundleVersions, pv =>
        _.isNull(pv.price) ||
        _.isUndefined(pv.price)
      );
      return !!anyNullPrice;
    }
    return false;
  }

  function isNullPricePlan(order) {
    if (!vm.order.product.isBundle) {
      return _.isEmpty(vm.productVersions) ||
        _.isNull(order.productVersion.price) ||
        _.isUndefined(order.productVersion.price);
    }
    return false;
  }

  function checkSettingsAndGetBudgetEstimate(_order) {
    return _checkSettingsAndGetBudgetEstimate(_order)
      .then(() => vm.pendingEstimateWithCoupon = vm.pendingEstimate = false);
  }

  function _checkSettingsAndGetBudgetEstimate(_order) {
    if (_.isEmpty(vm.productVersions)) {
      $log.info('No published product versions');
      return Promise.resolve();
    }

    if (_order.isCustomerCloudCredentialsOnly) {
      if (vm.cloudCredential.status === status.PENDING) return Promise.resolve();

      if (!_order.cloudCredential) {
        vm.order.showMandatoryCredentialsWarning = true;
      } else {
        vm.order.showMandatoryCredentialsWarning = void 0;
      }
    }

    const order = angular.copy(_order);

    vm.nullPriceVersion = isNullPriceBundle(order) || isNullPricePlan(order);

    $timeout(() => $scope.$digest(), 500);

    if (vm.product.isSyndicated && vm.productVersions.length === 1) {
      vm.product.customizable = !(_.isEmpty(vm.productVersions[0].billingItemValues) &&
        _.isEmpty(vm.productVersions[0].configurationParameters));
    }

    if (vm.nullPriceVersion) {
      $log.info('Null price version');
    }

    order.productVersion.billingItemApiPayAsYouGoGroups = _order.productVersion.billingItemApiPayAsYouGoGroups;
    order.productVersion.billingItemClassicPayAsYouGoGroups = _order.productVersion.billingItemClassicPayAsYouGoGroups;
    order.productVersion.billingItemApiPrepaidGroups = _order.productVersion.billingItemApiPrepaidGroups;
    order.productVersion.billingItemClassicPrepaidGroups = _order.productVersion.billingItemClassicPrepaidGroups;
    order.productVersion.billingItemApiPostpaidGroups = _order.productVersion.billingItemApiPostpaidGroups;
    order.productVersion.billingItemClassicPostpaidGroups = _order.productVersion.billingItemClassicPostpaidGroups;

    return settingsService.getSettings()
      .then(settings => {
        vm.showAskForInformation =
          vm.nullPriceVersion ||
          (settings.catalogMode === 'ANON_SHOWCASE' && !currentUser.isLogged) ||
          settings.catalogMode === 'SHOWCASE' ||
          configurationService.isDistributorChildMarketplace() ||
          configurationService.isParentMarketplace();
        if (vm.showAskForInformation || vm.order.showMandatoryCredentialsWarning) {
          $log.info('Disabling orders');
          return Promise.resolve();
        }
        else return _getBudgetEstimate(order);
      });
  }

  function _getBudgetEstimate(order) {
    if (!order.productVersion) return null;
    delete vm.couponErrorDetected;
    vm.budgetEstimateRequestObject = buildEstimateRequestObject(order);

    const comparableProductVersionUrl = order.productVersion.comparableVersion;
    if(!comparableProductVersionUrl) vm.comparableEstimate = void 0;
    else {
      UrlEntityService.retrieve(comparableProductVersionUrl, true)
        .tap(productVersionComparable => vm.productVersionComparable = productVersionComparable)
        .then(productVersionComparable => UrlEntityService.retrieve(productVersionComparable.product, true))
        .tap(productComparable => vm.productComparable = productComparable)
        .then(() => {
          const comparableEstimateObject = angular.copy(vm.budgetEstimateRequestObject);
          comparableEstimateObject.productVersionId = vm.productVersionComparable.id;
          comparableEstimateObject.hashCoupon = void 0;
          return BudgetEstimate.post(comparableEstimateObject, {skipError: true});
        })
        .then(comparableEstimate => vm.comparableEstimate = comparableEstimate)
        .catch(() => {
          vm.comparableEstimate = void 0;
          $log.warn(`Not comparable plan: ${comparableProductVersionUrl.url}`);
        });
    }

    return loadBudgetEstimate(vm.budgetEstimateRequestObject) // get budget estimate
      .then(
        returnBudgetEstimate, // then return it
        manageFailure(vm.budgetEstimateRequestObject) // something goes wrong
      )
      .then(() => {
        if(vm.order.productVersion.iaasBilling === 'PAY_AS_YOU_GO') {
          const requestObjectHourly = angular.copy(vm.budgetEstimateRequestObject);
          const requestObjectMonthly = angular.copy(vm.budgetEstimateRequestObject);
          requestObjectHourly['iaas'] = requestObjectMonthly['iaas'] = 'ONLY'; // jshint ignore:line
          requestObjectHourly['hours'] = 1; // jshint ignore:line
          requestObjectMonthly['hours'] = 720; // jshint ignore:line
          loadBudgetEstimate(requestObjectHourly)
            .then(estimateHourly => vm.budgetEstimateHourly = estimateHourly);
          loadBudgetEstimate(requestObjectMonthly)
            .then(estimateMonthly => vm.budgetEstimateMonthly = estimateMonthly);
        }
      });
  }

  function loadBudgetEstimate(requestObject) {
    return BudgetEstimate
      .post(requestObject, {
        language: vm.currentLanguage
      })
      .then(estimate => estimate.initialize());
  }

  function manageFailure(requestObject) {
    return function (error) {
      if (error.status !== 400) return null;
      vm.couponErrorDetected = true;
      delete requestObject.hashCoupon; // maybe an invalid coupon
      return loadBudgetEstimate(requestObject) // recalculate budget without coupon
        .then(returnBudgetEstimate);  // then return it
    };
  }

  function returnBudgetEstimate(budgetEstimate) {
    vm.budgetEstimate = budgetEstimate;

    if(vm.budgetEstimate.vmConfiguration && vm.budgetEstimate.vmConfiguration.ram) {
      vm.budgetEstimate.vmConfiguration.ram = vm.budgetEstimate.vmConfiguration.ram / 1024;
    }

    let groupedLines = _.groupBy(vm.budgetEstimate.lines, line => line.type);

    /*
    if price is 0 and no billing items prepaid -> it's free
    if price is 0 with billing items prepaid -> hide price, vat excluded, show a column like included in price
    if price not 0 -> show included in price
     */

    vm.budgetEstimate.showIncludedInPrice = groupedLines.VIRTUALMACHINE ||
      groupedLines.BANDWIDTH ||
      groupedLines.DISKSPACE ||
      planHasRequiredBillingItems(vm.order.productVersion.billingItemClassicPrepaidGroups) ||
      planHasRequiredBillingItems(vm.order.productVersion.billingItemApiPrepaidGroups);

    vm.budgetEstimate.hasMetrics = (
      !_.isEmpty(vm.order.productVersion.billingItemApiPayAsYouGoGroups) ||
      !_.isEmpty(vm.order.productVersion.billingItemClassicPayAsYouGoGroups)
    );

    vm.budgetEstimate.freePlan = !vm.budgetEstimate.hasMetrics &&
      (!vm.order.productVersion.iaasBilling || vm.order.productVersion.iaasBilling === 'PRE_PAID') &&
      vm.budgetEstimate.priceExcludingVAT === 0;

    vm.budgetEstimate.payAsYouGoIaasOnly = vm.order.productVersion.iaasBilling === 'PAY_AS_YOU_GO' &&
      vm.budgetEstimate.priceExcludingVAT === 0;

    vm.budgetEstimate.onlyMetrics = vm.budgetEstimate.hasMetrics &&
      !vm.budgetEstimate.showIncludedInPrice &&
      vm.budgetEstimate.priceExcludingVAT === 0;

    vm.budgetEstimate.payAsYouGo = vm.budgetEstimate.payAsYouGoIaasOnly || vm.budgetEstimate.onlyMetrics;

    return vm.budgetEstimate;
  }

  function buildEstimateRequestObject(order) {
    const ownerUsername = configurationService.getOwnerUsername();
    const resellerName = configurationService.isResellerChildMarketplace() ? ownerUsername : void 0;
    const distributorName = configurationService.isDistributorChildMarketplace() ? ownerUsername : void 0;

    var bObject = {
      reseller: resellerName,
      distributor: distributorName,
      productVersionId: order.productVersion.id,
      operatingSystem: order.operatingSystem,
      type: vm.estimateMode ? 'ESTIMATE' : 'NORMAL',
      configurationParameters: order.configurationParameters
    };

    if (order.hashCoupon) {
      $location.search('coupon', order.hashCoupon);
      bObject['hashCoupon'] = order.hashCoupon; // jshint ignore:line
    }

    vm.order.billingItems = productVersionEditService.collectBillingItems(order.productVersion);
    vm.order.configurationParameters = productVersionEditService
      .collectConfigurationParameters(vm.configurationParameters);

    // refresh configuration parameters based on the chosen
    retrieveConfigurationParameters(order.productVersion, vm.order.configurationParameters);

    bObject['billingItems'] = vm.order.billingItems; // jshint ignore:line

    if (needsCloudProvider()) {
      if (order.cloudProvider !== undefined) {
        bObject['providerId'] = order.cloudProvider.id; // jshint ignore:line
      }

      if (order.bandwidthPricing) {
        bObject['bandwidthPricingId'] = order.bandwidthPricing.id; // jshint ignore:line
      }

      bObject['credentialId'] = order.cloudCredential ? order.cloudCredential.id : void 0; // jshint ignore:line
    }

    return bObject;
  }

  function showDialog(url) {
    $window.open(url, '_blank');
  }

  function calculateBudgetEstimate() {
    return getBudgetEstimate(vm.order);
  }

  function showAdvancedSettings() {
    try {
      return vm.product && vm.order.productVersion && !_.isNil(vm.order.productVersion.price) &&
        (
          (vm.product.isCloudService && vm.order.productVersion.cloudProviders) ||
          (vm.product.isManaged && !vm.product.isVm && vm.order.productVersion.cloudProviders) ||
          (vm.product.isVm && vm.order.cloudProvider && vm.order.cloudProvider.supportedOperatingSystems.length >= 1)
        );
    } catch (e) {
      $log.error(e);
      return false;
    }
  }

  function onCloudProviderChanged(cloudProvider) {
    retrieveCloudCredentials(cloudProvider);
    selectOperatingSystem(cloudProvider);
  }

  function retrieveCloudCredentials(cloudProvider) {
    vm.cloudCredential.status = status.PENDING;
    vm.order.cloudCredential = void 0;
    vm.useCustomCredential = false;

    vm.order.isCustomerCloudCredentialsOnly = vm.order.product.cloudCredentialsSupport === 'ENFORCED' ||
      _.some(cloudProvider.features, featureObj => featureObj.feature === 'CUSTOM_CREDENTIALS_ONLY');

    if(!currentUser.isUser()) {
      vm.cloudCredential.status = status.COMPLETED;
      return;
    }
    getCustomerCloudCredentialsFeatureState()
      .then(() =>
        CloudCredential.getAll(cloudProvider)
          .then(cloudCredentials => {
            vm.order.cloudCredentials = cloudCredentials;

            if (vm.order.isCustomerCloudCredentialsOnly) {
              vm.useCustomCredential = true;
              vm.order.cloudCredential = _.first(cloudCredentials);
              vm.cloudCredential.status = status.COMPLETED;
            }
          })
      );
  }

  function selectOperatingSystem(cloudProvider) {
    if (vm.product.isVm) vm.order.operatingSystem = cloudProvider.supportedOperatingSystems[0];
  }

  unwatchers.push(
    $scope.$on('$stateChangeStart', (event, toState, toParams, fromState) => {
      if (fromState.name === 'marketplace.product.order' && toState.name === 'marketplace.product') {
        event.preventDefault();
        $state.go(toState, toParams, { reload: true, notify: false })
          .then($state.reload);
      }
    })
  );

  function getCustomerCloudCredentialsFeatureState() {
    const activeValues = ['ENABLED', 'ENFORCED'];
    return featureService.getFeatureState('customerCloudCredentials', activeValues)
      .then(() => {
        const product = vm.order.product;
        if(!product.cloudCredentialsSupport || _.includes(activeValues, product.cloudCredentialsSupport)) {
          return Promise.resolve(true);
        }
        return Promise.reject();
      });
  }

  function planHasRequiredBillingItems(billingItemValueGroups) {
    if(!billingItemValueGroups) return false;
    return _.some(billingItemValueGroups, billingItemValueIsRequired);
  }

  /*
    check if billingItemValue belongs to a required billing item
    avoid returning stairtstep billingItemValues with end = 0: or will be print "up to 0 itemName"
    avoid returning volume or tiered billingItemValues with start = 0: or will be print "0 itemName included"
   */
  function billingItemValueIsRequired(billingItemValueGroup) {
    if(!billingItemValueGroup) return false;
    const lastIndex = billingItemValueGroup.length - 1;
    if(billingItemValueGroup[0].start !== billingItemValueGroup[lastIndex].end) return false;

    const relatedBillingItem = billingItemValueGroup[0].item;
    const isRequired = relatedBillingItem.presence === 'REQUIRED';

    if(relatedBillingItem.valueType === 'PAY_AS_YOU_GO') {
      return isRequired;
    }

    if(relatedBillingItem.type === 'STAIRSTEP') {
      return isRequired && billingItemValueGroup[lastIndex].end !== 0; // may be null (null is accepted)
    }

    return isRequired && billingItemValueGroup[0].start > 0;
  }

  function setEstimateMode(value) {
    vm.estimateMode = value;
    vm.pendingEstimate = true;

    if(vm.estimateMode === false) {
      vm.order.productVersion.refreshBillingItems();
    }

    vm.calculateBudgetEstimate();
  }

  unwatchUseCustomCredentials = $scope.$watch(
      () => vm.useCustomCredential,
      () => {
        if(vm.useCustomCredential) vm.order.cloudCredential = vm.order.cloudCredentials[0];
        else vm.order.cloudCredential = void 0;
      }
    );

  $scope.$on('$destroy', () => {
    _destroy();
    unwatchLanguage();
    unwatchUseCustomCredentials();
  });
}
